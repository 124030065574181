import { motion } from 'framer-motion';
import React from 'react';
import { Link } from 'react-router-dom';

export const MenuHome = ({ categories }) => {
  const MotionLink = motion.create(Link);

  return (
    <div className="grid gap-4 max-w-4xl mx-auto p-2">
      {categories.map((category) => (
        category.ImageUrl?
        <MotionLink key={category.Id} to={category.LinkTo} whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.9 }} className="block">
          <div className="relative h-48 rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow">
            <img src={category.ImageUrl || '/api/placeholder/400/320'} alt={category.Description} className="w-full h-full object-cover" />
            <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
            <div className="absolute bottom-0 left-0 right-0 p-4">
              <h2 className="text-white text-xl font-semibold">
                {category.Description}
              </h2>
              {category.Notes && (
                <p className="text-white/80 text-sm mt-1">{category.Notes}</p>
              )}
            </div>
          </div>
        </MotionLink>
        :
        <MotionLink key={category.Id} to={category.LinkTo} whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.9 }} className="block">
        <div className="relative h-48 overflow-hidden shadow-lg hover:shadow-xl transition-shadow bg-white border-violet-100 dark:bg-gxp_dark_3 dark:border-gray-800 text-gray-600 dark:text-white border-2 rounded-lg  p-4 mb-4 duration-300">
          <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
          <div className="absolute bottom-0 left-0 right-0 p-4">
            <h2 className="text-white text-xl font-semibold">
              {category.Description}
            </h2>
            {category.Notes && (
              <p className="text-white/80 text-sm mt-1">{category.Notes}</p>
            )}
          </div>
        </div>
      </MotionLink>

      ))
      }

    </div>
  );
};