import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { MobileHeader } from "../components/Layout/MobileHeader";
import { MobileFooter } from "../components";

export const RootLayout = () => {
  const { pathname } = useLocation();
  const isDirect = pathname.includes("direct");

  return (
    <div className="min-h-screen flex flex-col bg-gxp_violet_violet_bg dark:bg-gxp_dark_2">
      {/* Header Section */}
      {!isDirect && (
          <div className="">
            <MobileHeader />
          </div>
      )}

      {/* Main Content Section */}
      <div className={`flex-1 flex ${isDirect ? "" : "pb-20"}`}>
        {/* Main Content Area */}
        <main className="flex-1 flex flex-col flex-grow w-full">
          <div className="flex-1 w-full z-0">
            <Outlet />
          </div>

        </main>
      </div>

      {/* Mobile Footer */}
      {!isDirect && (
        <div className="z-100">
          <MobileFooter />
        </div>
      )}
    </div>
  );
};