import { useTranslation } from "react-i18next";
import { AlertTriangle } from "lucide-react";
import React from "react";

/**
 * @module LastName
 * @description Component for entering a guest's last name.
 * This component provides a text input field for the guest's last name,
 * indicating whether it is a required field based on the provided validation rules.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {function} props.handleGuestChange - Function to handle changes in guest data.
 * @param {Object} props.guest - The guest object containing last name data.
 * @param {Array<Object>} props.requiredFields - List of required fields for validation,
 *                                               where each object contains:
 *                                               - {string} Field - The field name.
 *                                               - {boolean} IsMandatory - Indicates if the field is required.
 *
 * @returns {JSX.Element} The rendered input field for the last name.
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 *
 * @example
 * // Example usage of the LastName component
 * <LastName
 *   handleGuestChange={handleGuestChange}
 *   guest={guestData}
 *   requiredFields={requiredFields}
 * />
 */
export const LastName = ({ handleGuestChange, guest, requiredFields, error, cS }) => {
  const [t] = useTranslation("global");

    // Check if the LastName field is required based on the provided validation rules
    const isRequired = requiredFields.some(
      field => field.Field === "LastName" && field.IsMandatory);

  return (
    <div>
    <label htmlFor="apelido" required={isRequired} className={`pl-2 inline-block rounded-xl translate-y-3.5 text-center justify-center translate-x-3 bg-white dark:bg-gxp_dark_3 text-sm font-medium text-gray-700 dark:text-white mb-1 ${parseInt(cS) ? "OC-bg" : ``}`} >{`${t(`LastName.lastname`)}`}<span className="text-gray-400 dark:text-white ml-2">{isRequired?`( * )\u00A0`: ``}</span></label>
    <input name="LastName" onChange={(e)=>handleGuestChange(guest.id,e.target.name,e.target.value)} value={guest.data.LastName || ""} type="text" id="apelido" className={`w-full px-4 py-2 text-xl rounded-lg border dark:bg-gxp_dark_3 dark:text-white dark:border-white ${error ? 'border-red-500' : 'border-gray-700'} ${parseInt(cS) ? "OC-fields-borders OC-bg" : ``} focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out`} />
    {error && <div className="flex mt-1 items-center"> <AlertTriangle className="text-red-500 mr-1" size={16} /> <span className="text-red-500 text-sm">{`${t(`HostForm.fieldRequired`)}`}</span> </div>}
    </div>
    )
}
