export const searchInItems = (items, searchText) => {
    if (!searchText || !items) return [];

    const searchLower = searchText.toLowerCase();

    // Função recursiva para coletar todos os itens finais
    const getAllItems = (item) => {
        let results = [];

        // Se é um item final (tem ItemId > 0), verifica se corresponde à busca
        if (item.ItemId > 0) {
            const descriptionMatch = item.Description?.toLowerCase().includes(searchLower);
            const notesMatch = item.Notes?.toLowerCase().includes(searchLower);

            if (descriptionMatch || notesMatch) {
                results.push(item);
            }
        }

        // Se tem children, busca recursivamente neles
        if (item.Children) {
            item.Children.forEach(child => {
                results = results.concat(getAllItems(child));
            });
        }

        return results;
    };

    // Aplica a busca em todos os itens do menu
    let results = [];
    items.forEach(item => {
        results = results.concat(getAllItems(item));
    });

    console.log(results)

    return results;
};