import { useTranslation } from "react-i18next";
import { useState, useMemo, useEffect } from "react";
import { Select } from "antd";
import { ChevronDown, Search, SearchIcon } from "lucide-react";
import { AlertTriangle } from "lucide-react";
import { PostalCodeModal } from "./PostalCodeModal";

/**
 * @module Address
 * @description Component for managing the address information of a guest.
 * This component includes input fields for country selection, address, city,
 * and postal code, along with validation based on required fields.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.guest - The guest object containing address data.
 * @param {function} props.handleGuestChange - Function to handle changes in guest data.
 * @param {Object} props.initialData - Initial data including the list of countries.
 * @param {Array<Object>} props.requiredFields - List of required fields for validation,
 *                                               where each object contains:
 *                                               - {string} Field - The field name.
 *                                               - {boolean} IsMandatory - Indicates if the field is required.
 *
 * @returns {JSX.Element} The rendered address form fields.
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 *
 * @example
 * // Example usage of the Address component
 * <Address
 *   guest={guestData}
 *   handleGuestChange={handleGuestChange}
 *   initialData={initialData}
 *   requiredFields={requiredFields}
 * />
 */
export const Address = ({ guest, handleGuestChange, initialData, requiredFields, error, cS, isSameCountry }) => {
  const [t] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleConfirm = () => {
    // Lógica para confirmar o endereço
    setIsModalOpen(false);
  };

  const countries = useMemo(() => {
    return initialData.CountryList.map((country) => ({
      ...country,
    }));
  }, [initialData.CountryList]);

  const regions = useMemo(() => {
    return initialData.RegionList.map((region) => ({
      ...region,
    }));
  }, [initialData.RegionList]);

  const getCountryLabel = (countryIdOrIso) => {
    if (initialData) {
      const country = initialData.CountryList.find(
        (country) => country.Id === countryIdOrIso || country.IsoCode === countryIdOrIso );
      return country ? country.TranslatedDescription : "";
    }
  };

  const getRegionLabel = (regionIdOrIso) => {
    if (initialData) {
      const region = initialData.RegionList.find(
        (region) => region.Id === regionIdOrIso || region.Code === regionIdOrIso );
      return region ? region.Description : "";
    }
  };

  const getCountryValue = () => {
    if (guest.data.CountryId) {
      return guest.data.CountryId;
    } else if (guest.data.CountryIso) {
      const country = initialData.CountryList.find(
        (c) => c.IsoCode === guest.data.CountryIso
      );
      return country ? country.Id : undefined;
    }
    return undefined;
  };

  const countryValue = getCountryValue();

  const getRegionValue = () => {
    if (guest.data.RegionId) {
      return guest.data.RegionId;
    } else if (guest.data.RegionCode) {
      const region = initialData.RegionList.find(
        (c) => c.Code === guest.data.RegionCode
      );
      return region ? region.Id : undefined;
    }
    return undefined;
  };

  const regionValue = getRegionValue();


  /**
   * Handles the change event when a country is selected.
   *
   * @function handleCountryChange
   * @param {Event} e - The change event from the select element representing country selection.
   * @param {number} id - The ID of the guest whose country is being changed.
   * @returns {void} No return value.
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   *
   * @example
   * // Example usage of handleCountryChange
   * handleCountryChange(event, guest.id);
   */
  const handleCountryChange = (e, id) => {
    const selectedCountry = JSON.parse(e.target.value);
    handleGuestChange(id, "CountryId", selectedCountry.Id);
    handleGuestChange(id, "CountryIso", selectedCountry.IsoCode);
  };

  // Determine whether each field is required based on the provided validation rules
  const isRequiredCountryId = requiredFields.some(
    (field) => field.Field === "CountryId" && field.IsMandatory
  );

  const isRequiredAddress1 = requiredFields.some(
    (field) => field.Field === "Address1" && field.IsMandatory
  );

  const isRequiredAddress2 = requiredFields.some(
    (field) => field.Field === "Address2" && field.IsMandatory
  );

  const isRequiredAddress3 = requiredFields.some(
    (field) => field.Field === "Address3" && field.IsMandatory
  );

  const isRequiredAddressState = requiredFields.some(
    (field) => field.Field === "AddressState" && field.IsMandatory
  );

  const isRequiredRegionId = requiredFields.some(
    (field) => field.Field === "RegionId" && field.IsMandatory
  );

  const isRequiredTown = requiredFields.some(
    (field) => field.Field === "Town" && field.IsMandatory
  );

  const isRequiredZipCode = requiredFields.some(
    (field) => field.Field === "ZipCode" && field.IsMandatory
  );

  // console.log("isSameCountry",isSameCountry)
  // console.log("Req",isRequiredAddress3)
  // console.log("error",error)

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-3 max-sm:gap-y-0 gap-3">
        <div className="flex flex-col">
          <label htmlFor="genero" className={`w-fit pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-left justify-center translate-x-3 bg-white dark:bg-gxp_dark_3 text-sm font-medium text-gray-700 dark:text-white mb-1 z-10 ${parseInt(cS) ? "OC-bg" : ``}`} >
          {`${t(`Address.country`)}`} <span className="text-gray-400 dark:text-white ml-2"> {isRequiredCountryId ? `( * )\u00A0` : ``} </span>
          </label>
          <Select
            suffixIcon={ <ChevronDown className={`text-gray-700 dark:text-white transition-transform duration-300 ease-in-out ${ isOpen ? "rotate-180" : "" }`} /> }
            onDropdownVisibleChange={(open) => setIsOpen(open)}
            className={`w-full py-2 text-xl rounded-lg border text-gray-700 ${error.CountryId ? 'border-red-500' : 'border-gray-700 dark:border-white'} ${parseInt(cS) ? "OC-fields-borders OC-bg" : ``} focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out appearance-none`}
            style={{ height: "45.5px" }}
            onChange={(selected) => { handleGuestChange(guest.id, "CountryId", selected.value); const selectedCountry = initialData.CountryList.find( (c) => c.Id === selected.value ); if (selectedCountry) { handleGuestChange( guest.id, "CountryIso", selectedCountry.IsoCode ); } }}
            placeholder={ <span className="text-gray-700 dark:text-gray-400"> {`${t(`Address.select`)}`} </span> }
            optionLabelProp="label"
            popupClassName={`dark:dark`}
            showSearch
            dropdownStyle={{ minWidth: "260px" }}
            labelInValue
            value={ countryValue ? { value: countryValue, label: getCountryLabel(countryValue) } : undefined }
            filterOption={(input, option) => { const country = countries.find( (country) => country.Id === option.value ); return country?.TranslatedDescription ?.toLowerCase() .includes(input.toLowerCase()); }}
            virtual={false}
          >
            {countries.map((country) => (
              <Select.Option key={country.Id} className={`dark:dark`} value={country.Id} label={ <div className="flex items-center"> <img src={`https://flagcdn.com/w20/${country.IsoCode.toLowerCase()}.png`} alt={`${country.TranslatedDescription} flag`} className="w-6 h-4 mr-2" /> <span className="text-l">{country.dialCode}</span> </div> } >
                <div className="flex items-center">
                  <img src={`https://flagcdn.com/w20/${country.IsoCode.toLowerCase()}.png`} alt={`${country.TranslatedDescription} flag`} className="w-6 h-4 mr-2" />
                  <span className="text-l">{country.TranslatedDescription}</span>
                </div>
              </Select.Option>
            ))}
          </Select>
        {error.CountryId && ( <div className="flex mt-1 items-center"> <AlertTriangle className="text-red-500 mr-1" size={16} /> <span className="text-red-500 text-sm">{error.CountryId}</span>{" "} </div> )}
        </div>


        <div>
          <label className={`pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-center justify-center translate-x-3 bg-white dark:bg-gxp_dark_3 text-sm font-medium text-gray-700 dark:text-white mb-1 ${parseInt(cS) ? "OC-bg" : ``}`} >
            {`${t(`Address.address1`)}`}
            <span className="text-gray-400 dark:text-white ml-2"> {isRequiredAddress1 ? `( * )\u00A0` : ``} </span>
          </label>
          <input name="Address1" required={isRequiredAddress1} onChange={(e) => handleGuestChange(guest.id, e.target.name, e.target.value) } value={guest.data.Address1 || ""} type="text" id="Address1" className={`w-full px-4 py-2 rounded-lg dark:text-white dark:bg-gxp_dark_3 border ${error.Address1 ? 'border-red-500' : 'border-gray-700 dark:border-white'} ${parseInt(cS) ? "OC-fields-borders OC-bg" : ``} focus:ring-2 text-xl focus:border-blue-500 transition duration-150 ease-in-out`} />
          {error.Address1 && ( <div className="flex mt-1 items-center"> <AlertTriangle className="text-red-500 mr-1" size={16} /> <span className="text-red-500 text-sm">{error.Address1}</span>{" "} </div> )}
        </div>

        { initialData.Hotel.CountryCodeLic==="BR" && isSameCountry &&
        <div>
          <label className={`pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-center justify-center translate-x-3 bg-white dark:bg-gxp_dark_3 text-sm font-medium text-gray-700 dark:text-white mb-1 ${parseInt(cS) ? "OC-bg" : ``}`} >
            {`${t(`Address.address2`)}`}
            <span className="text-gray-400 dark:text-white ml-2"> {isRequiredAddress2 ? `( * )\u00A0` : ``} </span>
          </label>
          <input name="Address2" required={isRequiredAddress2} onChange={(e) => handleGuestChange(guest.id, e.target.name, e.target.value) } value={guest.data.Address2 || ""} type="text" id="Address2" className={`w-full px-4 py-2 rounded-lg dark:text-white dark:bg-gxp_dark_3 border ${error.Address2 ? 'border-red-500' : 'border-gray-700 dark:border-white'} ${parseInt(cS) ? "OC-fields-borders OC-bg" : ``} focus:ring-2 text-xl focus:border-blue-500 transition duration-150 ease-in-out`} />
          {error.Address2 && ( <div className="flex mt-1 items-center"> <AlertTriangle className="text-red-500 mr-1" size={16} /> <span className="text-red-500 text-sm">{error.Address2}</span>{" "} </div> )}
        </div>}

        { initialData.Hotel.CountryCodeLic==="BR" && isSameCountry &&
        <div>
          <label className={`pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-center justify-center translate-x-3 bg-white dark:bg-gxp_dark_3 text-sm font-medium text-gray-700 dark:text-white mb-1 ${parseInt(cS) ? "OC-bg" : ``}`} >
            {`${t(`Address.address3`)}`}
            <span className="text-gray-400 dark:text-white ml-2"> {isRequiredAddress3 ? `( * )\u00A0` : ``} </span>
          </label>
          <input name="Address3" required={isRequiredAddress3} onChange={(e) => handleGuestChange(guest.id, e.target.name, e.target.value) } value={guest.data.Address3 || ""} type="text" id="Address3" className={`w-full px-4 py-2 rounded-lg dark:text-white dark:bg-gxp_dark_3 border ${error.Address3 ? 'border-red-500' : 'border-gray-700 dark:border-white'} ${parseInt(cS) ? "OC-fields-borders OC-bg" : ``} focus:ring-2 text-xl focus:border-blue-500 transition duration-150 ease-in-out`} />
          {error.Address3 && ( <div className="flex mt-1 items-center"> <AlertTriangle className="text-red-500 mr-1" size={16} /> <span className="text-red-500 text-sm">{error.Address3}</span>{" "} </div> )}
        </div>}

        { initialData.Hotel.CountryCodeLic==="BR" && isSameCountry &&
        <div>
          <label className={`pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-center justify-center translate-x-3 bg-white dark:bg-gxp_dark_3 text-sm font-medium text-gray-700 dark:text-white mb-1 ${parseInt(cS) ? "OC-bg" : ``}`} >
            {`${t(`Address.addressState`)}`}
            <span className="text-gray-400 dark:text-white ml-2"> {isRequiredAddressState ? `( * )\u00A0` : ``} </span>
          </label>
          <input name="AddressState" required={isRequiredAddressState} onChange={(e) => handleGuestChange(guest.id, e.target.name, e.target.value) } value={guest.data.AddressState || ""} type="text" id="AddressState" className={`w-full px-4 py-2 rounded-lg dark:text-white dark:bg-gxp_dark_3 border ${error.AddressState ? 'border-red-500' : 'border-gray-700 dark:border-white'} ${parseInt(cS) ? "OC-fields-borders OC-bg" : ``} focus:ring-2 text-xl focus:border-blue-500 transition duration-150 ease-in-out`} />
          {error.AddressState && ( <div className="flex mt-1 items-center"> <AlertTriangle className="text-red-500 mr-1" size={16} /> <span className="text-red-500 text-sm">{error.AddressState}</span>{" "} </div> )}
        </div>}

        { initialData.Hotel.CountryCodeLic==="BR" && isSameCountry &&
        <div className="flex flex-col">
          <label className={`w-fit pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-left justify-center translate-x-3 bg-white dark:bg-gxp_dark_3 text-sm font-medium text-gray-700 dark:text-white mb-1 z-10 ${parseInt(cS) ? "OC-bg" : ``}`} >
          {`${t(`Address.region`)}`} <span className="text-gray-400 dark:text-white ml-2"> {isRequiredRegionId ? `( * )\u00A0` : ``} </span>
          </label>
          <Select
            suffixIcon={ <ChevronDown className={`text-gray-700 dark:text-white transition-transform duration-300 ease-in-out ${ isOpen ? "rotate-180" : "" }`} /> }
            onDropdownVisibleChange={(open) => setIsOpen(open)}
            className={`w-full py-2 text-xl rounded-lg border text-gray-700 ${error.RegionId ? 'border-red-500' : 'border-gray-700 dark:border-white'} ${parseInt(cS) ? "OC-fields-borders OC-bg" : ``} focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out appearance-none`}
            style={{ height: "45.5px" }}
            onChange={(selected) => { handleGuestChange(guest.id, "RegionId", selected.value); const selectedRegion = initialData.RegionList.find( (c) => c.Id === selected.value ); if (selectedRegion) { handleGuestChange( guest.id, "RegionCode", selectedRegion.Code ); } }}
            placeholder={ <span className="text-gray-700 dark:text-gray-400"> {`${t(`Address.select`)}`} </span> }
            optionLabelProp="label"
            popupClassName={`dark:dark`}
            showSearch
            dropdownStyle={{ minWidth: "260px" }}
            labelInValue
            value={ regionValue ? { value: regionValue, label: getRegionLabel(regionValue) } : undefined }
            filterOption={(input, option) => { const region = regions.find( (region) => region.Id === option.value ); return region?.Description ?.toLowerCase() .includes(input.toLowerCase()); }}
            virtual={false}
          >
            {regions.map((region) => (
              <Select.Option key={region.Id} className={`dark:dark`} value={region.Id}  >
                <div className="flex items-center">
                  <span className="text-l">{region.Description}</span>
                </div>
              </Select.Option>
            ))}
          </Select>
        {error.RegionId && ( <div className="flex mt-1 items-center"> <AlertTriangle className="text-red-500 mr-1" size={16} /> <span className="text-red-500 text-sm">{error.RegionId}</span>{" "} </div> )}
        </div>}

        <div>
          <label htmlFor="city" className={`pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-center justify-center translate-x-3 bg-white dark:bg-gxp_dark_3 text-sm font-medium text-gray-700 dark:text-white mb-1 ${parseInt(cS) ? "OC-bg" : ``}`} >
            {`${t(`Address.city`)}`}
            <span className="text-gray-400 dark:text-white ml-2"> {isRequiredTown ? `( * )\u00A0` : ``} </span>
          </label>
          <input name="Town" required={isRequiredTown} onChange={(e) => handleGuestChange(guest.id, e.target.name, e.target.value) } value={guest.data.Town || ""} type="text" id="Town" className={`w-full px-4 py-2 rounded-lg border dark:text-white dark:bg-gxp_dark_3 ${error.Town ? 'border-red-500' : 'border-gray-700 dark:border-white'} ${parseInt(cS) ? "OC-fields-borders OC-bg" : ``} focus:ring-2 text-xl focus:border-blue-500 transition duration-150 ease-in-out`} />
          {error.Town && ( <div className="flex mt-1 items-center"> <AlertTriangle className="text-red-500 mr-1" size={16} /> <span className="text-red-500 text-sm">{error.Town}</span>{" "} </div> )}
        </div>



        <div className="relative">
          <label htmlFor="postalCode" className={`pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-center justify-center translate-x-3 bg-white dark:bg-gxp_dark_3 text-sm font-medium text-gray-700 dark:text-white mb-1 ${parseInt(cS) ? "OC-bg" : ``}`} >
            {`${t(`Address.zipcode`)}`}
            <span className="text-gray-400 dark:text-white ml-2"> {isRequiredZipCode ? `( * )\u00A0` : ``} </span>
          </label>
          <input name="ZipCode" required={isRequiredZipCode} onChange={(e) => handleGuestChange(guest.id, e.target.name, e.target.value) } value={guest.data.ZipCode || ""} type="text" id="ZipCode" className={`w-full px-4 py-2 rounded-lg border dark:text-white dark:bg-gxp_dark_3 ${error.ZipCode ? 'border-red-500' : 'border-gray-700 dark:border-white'} ${parseInt(cS) ? "OC-fields-borders OC-bg" : ``} focus:ring-2 text-xl focus:border-blue-500 transition duration-150 ease-in-out`}  />
            {initialData.Hotel.CountryCodeLic==="BR" && isSameCountry &&
          <span className="absolute top-1/2 right-3 transform flex items-center cursor-pointer dark:text-white dark:hover:text-gxp_dark_2">
            <Search onClick={() => setIsModalOpen(true)}/>
          </span>}
          {error.ZipCode && ( <div className="flex mt-1 items-center"> <AlertTriangle className="text-red-500 mr-1" size={16} /> <span className="text-red-500 text-sm">{error.ZipCode}</span>{" "} </div> )}
        </div>

      </div>
      <PostalCodeModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onConfirm={handleConfirm} initialData={initialData} guest={guest} handleGuestChange={handleGuestChange} />
    </>
  );
};
