import onlineCheckInDesktop from "../../assets/images/onlineCheckInDesktop.jpeg";
import onlineCheckIn from "../../assets/images/onlineCheckIn.jpeg";
import hhsLogo from "../../assets/images/hhsLogo.png";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";

export const HeadersPage = ({ title, room, militaryMode, guest, post }) => {
  const [t] = useTranslation("global");
  const [cookie] = useCookies(["guest"]);

  return (
    <section>
      <div className="sm:hidden">
          <div className="flex-col text-white flex justify-between bg-cover h-40" style={{ backgroundImage: `url(${onlineCheckInDesktop})`}}>
            <div className="flex justify-center items-center h-full w-ful">
              <img src={hhsLogo} className={`w-1/4`} alt="logo" />
            </div>
            <div className="flex ml-6">
              <h1 className={`text-3xl font-bold text-white mb-2 sm:mb-4`}> {" "} {title}{" "} </h1>
            </div>
          </div>
            <div className=" bg-blue-600 w-full py-4 items-center flex justify-between">
              { militaryMode && post?
                <h1 className=" text-lg ml-6 font-bold text-white">{`${post.Description} ${cookie.guest?.LastName}`}</h1>
                :
                <h1 className=" text-lg ml-6 font-bold text-white"> {" "} {t("Checkout.welcome")} {cookie.guest?.LastName} {cookie.guest?.FirstName} </h1>
              }
              <span className="text-lg mr-6 text-white"> { room==="" || room===undefined|| room===null ? "" :`${t("Checkout.room")} ${room}`} </span>
            </div>
      </div>

      <div className="max-sm:hidden lg:hidden">
          <div className="flex-col text-white flex justify-between bg-cover h-52" style={{ backgroundImage: `url(${onlineCheckInDesktop})`}}>
            <div className="flex justify-center items-center h-full w-ful">
              <img src={hhsLogo} className={`h-1/2`} alt="logo" />
            </div>
            <div className="flex ml-6">
              <h1 className={`text-4xl font-bold text-white mb-2 sm:mb-4`}> {" "} {title}{" "} </h1>
            </div>
          </div>
            <div className=" bg-blue-600 w-full py-4 items-center flex justify-between">
            { militaryMode && post?
                <h1 className=" text-lg ml-6 font-bold text-white">{`${post.Description} ${cookie.guest?.LastName}`}</h1>
                :
                <h1 className=" text-lg ml-6 font-bold text-white"> {" "} {t("Checkout.welcome")} {cookie.guest?.LastName} {cookie.guest?.FirstName} </h1>
              }
              <span className="text-lg mr-6 text-white"> { room==="" || room===undefined|| room===null ? "" :`${t("Checkout.room")} ${room}`}</span>
            </div>
      </div>

      <div className="max-lg:hidden">
          <div className="flex-col text-white flex justify-between bg-cover h-52" style={{ backgroundImage: `url(${onlineCheckInDesktop})`}}>
            <div className="flex justify-center items-center h-full w-ful">
              <img src={hhsLogo} className={`h-2/3`} alt="logo" />
            </div>
            <div className="flex ml-6">
              <h1 className={`text-5xl font-bold text-white mb-2 sm:mb-4`}> {" "} {title}{" "} </h1>
            </div>
          </div>
            <div className=" bg-blue-600 w-full py-4 items-center flex justify-between">
            { militaryMode && post?
                <h1 className=" text-lg ml-6 font-bold text-white">{`${post.Description} ${cookie.guest?.LastName}`}</h1>
                :
                <h1 className=" text-lg ml-6 font-bold text-white"> {" "} {t("Checkout.welcome")} {cookie.guest?.LastName} {cookie.guest?.FirstName} </h1>
              }
              <span className="text-lg mr-6 text-white"> { room==="" || room===undefined|| room===null ? "" :`${t("Checkout.room")} ${room}`} </span>
            </div>
      </div>

    </section>
  );
};
