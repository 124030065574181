import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import React from "react";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "../../hooks/useWindowSize";
import { QRCode } from "antd";
import { getKeypassKey, getReservationByDetailId } from "../../services";
import { HeadersPage } from "../../components/Layout/HeadersPage";
import { Loader } from "../../components/Layout/components/Loader/Loader";

/**
 * @module QrCode
 * @description Displays a QR code for a reservation using data fetched from the backend.
 * Includes error handling, dark mode support, and responsive design.
 *
 * @author Tiago Ferreira
 * @version 1.0.0
 * @example
 * <QrCode />
 */
export const QrCode = () => {
  const [keypassKeyString, setKeypassKeyString] = useState("");
  const { detailId } = useParams();
  const [cookie] = useCookies(["sUid", "currentHotel"]);
  const [state, dispatch] = useContext(AppContext); // eslint-disable-line
  const [t] = useTranslation("global");
  const [reservation, setReservation] = useState(state.reservation);
  const { isMobile } = useWindowSize();
  const [isLoading, setIsLoading] = useState(true);
  const [darkMode, setDarkMode] = useState( JSON.parse(localStorage.getItem(`darkMode`)) || false );

  /**
   * @function getKeypassKeyString
   * @description Fetches the KeyPass key for the reservation.
   * Updates the state with the fetched key string or shows an error toast on failure.
   *
   * @async
   * @returns {void}
   * @author Tiago Ferreira
   * @version 1.0.0
   * @example
   * useEffect(() => {
   *   getKeypassKeyString();
   * }, []);
   */
  const getKeypassKeyString = async () => {
    try {
      setIsLoading(true);
      const result = await getKeypassKey(detailId, cookie.currentHotel, cookie.sUid);
      setKeypassKeyString(result);
    } catch (error) {
      console.log(error);
      toast.error("Unable to get KeyPass Key!");
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * @function fetchReservationDetails
   * @description Fetches reservation details using the `detailId`.
   * Sets the reservation state or displays an error toast if the fetch fails.
   *
   * @async
   * @returns {void}
   *
   * @author Tiago Ferreira
   * @version 1.0.0
   *
   * @example
   * useEffect(() => {
   *   fetchReservationDetails();
   * }, [detailId]);
   */
  const fetchReservationDetails = async () => {
    try {
      const data = await getReservationByDetailId(detailId, cookie.currentHotel, cookie.sUid);
      setReservation(data[0]);
      dispatch({ type: "SET_USER_RESERVATION", payload:data[0] });
    } catch (error) {
      console.error("App: Error fetching checkout info:", error);
      toast.error("App: Unable to get checkout info!");
    }
  };

  // Fetch the KeyPass key on initial load if required parameters are available.
  useEffect(() => {
    if (detailId && cookie.sUid && cookie.currentHotel) {
      getKeypassKeyString();
    }
  }, []); // eslint-disable-line

  // Fetch reservation details on initial load.
  useEffect(() => {
    if(!reservation){
      fetchReservationDetails();
    }
  }, [detailId]); // eslint-disable-line

  return (
    <>
      {reservation && (
        <div className={`antialiased h-full w-full`}>
          <section className="h-full w-full">
            <HeadersPage title={`${t(`QrCode.qrcode`)}`} room={reservation?.room} />
            <div className="flex flex-col justify-center items-center mt-5">
              {isLoading ? (
                <Loader />
              ) : (
                <QRCode key={darkMode} value={keypassKeyString || ""} status={isLoading ? "loading" : "active"} size={256} errorLevel="H" color={"#000000"} bgColor="#FFFFFF" bordered />
              )}
            </div>
          </section>
        </div>
      )}
    </>
  );
};