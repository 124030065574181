import { useEffect, useState } from "react";
import { ReservationCard } from "./ReservationCard";
import { ChevronDown, MapPin } from "lucide-react";
import { useTranslation } from "react-i18next";

export const HotelGroup = ({ reservations, isLoading, setIsLoading }) => {
  const [expandedState, setExpandedState] = useState({});
  const [t] = useTranslation("global");

  useEffect(() => {
    const initialState = reservations.reduce((acc, reservation) => {
      acc[reservation.hotel.id] = true; // Inicialmente, expandimos todos os hotéis
      return acc;
    }, {});
    setExpandedState(initialState);
  }, [reservations]);

  const toggleExpansion = (hotelId) => {
    setExpandedState(prevState => ({
      ...prevState,
      [hotelId]: !prevState[hotelId]
    }))};

   return (
    <>
    { reservations.length===0 ?
      (<div className="flex items-center">
        <h3 className="text-lg font-semibold py-4 dark:text-white">{`${t("HotelGroup.requirement")}`}</h3>
      </div>)
      :
      (expandedState &&
        <div className="">
      {reservations.filter((reservation)=>reservation.reservations.length>0).map((reservation) => (
        <div key={reservation.hotel.id} className="">
          <div className="flex items-center justify-between rounded-t-lg cursor-pointer" onClick={() => toggleExpansion(reservation.hotel.id)} >
            <div className="flex items-center">
              <MapPin className="w-5 h-5 mr-2 text-gray-600 dark:text-white" />
              <h3 className="text-lg font-semibold py-2 dark:text-white">{reservation.hotel.name2}</h3>
            </div>
            <div className="flex items-center">
              <span className="mr-2 text-sm text-gray-600 dark:text-white"> {reservation.reservations.length} {`${t("HotelGroup.reservation")}`}{reservation.reservations.length !== 1 ? 's' : ''} </span>
              <ChevronDown className={`transition-transform duration-200 dark:text-white ${ expandedState[reservation.hotel.id] ? "rotate-180" : "rotate-0" }`} />
            </div>
          </div>
          <div className={`rounded-b-lg transition-all duration-300 ease-in-out overflow-hidden ${expandedState[reservation.hotel.id] ? "opacity-100" : "max-h-0 opacity-0" }`} >
            <div className="">
              <ReservationCard reservations={reservation} isLoading={isLoading} setIsLoading={setIsLoading} />
            </div>
          </div>
        </div>
      ))}
    </div>
  )}
  </>
  );
};
