import { Swiper, SwiperSlide } from "swiper/react";


// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "../../../index.css";
import { Autoplay } from "swiper/modules";
import { FreeMode } from 'swiper/modules';
import { SliderCardHotel } from "./SliderCardHotel";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { useMenuDataProcessor } from "../../../hooks/useMenuDataProcessor";

export const SliderSection = ({ posSection }) => {
  const [menuData, setMenuData] = useState([]);
  const [state, dispatch] = useContext(AppContext);

  const processMenuData = useMenuDataProcessor( posSection, setMenuData );

  useEffect(() => {
    if (state.hotelMenu && state.reservation) {
      processMenuData();
    }
  }, [state.hotelMenu, state.reservation]);

  return (
    (menuData &&
    <section className="flex flex-wrap justify-between pl-3 z-10">
      <div className="max-w-full">
        <Swiper spaceBetween={10} breakpoints={{ 320: { slidesPerView: 1.5, spaceBetween: 10 }, 640: { slidesPerView: 2.5, spaceBetween: 15 }, 768: { slidesPerView: 3.5, spaceBetween: 15 } }}  autoplay={{ delay: 2500, disableOnInteraction: false, pauseOnMouseEnter: true, }} loop={true} modules={[FreeMode, Autoplay]} className="mySwiper" >
          {menuData
            .map((subItem, index) => (
              <SwiperSlide className="overflow-clip px-1 py-4" key={index} >
                <SliderCardHotel subItem={subItem} index={index} className />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </section>
    )
  );
};
