import { useTranslation } from "react-i18next";
import { AlertTriangle } from "lucide-react";
import { Select } from "antd";
import { ChevronDown } from "lucide-react";
import { useState } from "react";

/**
 * @module Gender
 * @description Component for selecting a guest's gender.
 * This component provides a dropdown menu for selecting
 * the gender of the guest, with options for Male, Female, and Other.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {function} props.handleGuestChange - Function to handle changes in guest data.
 * @param {Object} props.guest - The guest object containing gender data.
 * @param {Array<Object>} props.requiredFields - List of required fields for validation,
 *                                               where each object contains:
 *                                               - {string} Field - The field name.
 *                                               - {boolean} IsMandatory - Indicates if the field is required.
 *
 * @returns {JSX.Element} The rendered gender selection dropdown.
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 *
 * @example
 * // Example usage of the Gender component
 * <Gender
 *   handleGuestChange={handleGuestChange}
 *   guest={guestData}
 *   requiredFields={requiredFields}
 * />
 */
export const Gender = ({handleGuestChange, guest, requiredFields, error, cS}) => {
  const [t] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);

  const genderOptions = [
    { value: 'F', label: t('Gender.female') },
    { value: 'M', label: t('Gender.male') },
    { value: 'O', label: t('Gender.other') }
  ];

  const getGenderLabel = (value) => {
    const option = genderOptions.find(opt => opt.value === value);
    return option ? option.label : '';
  };

    // Check if the Gender field is required based on the provided validation rules
  const isRequired = requiredFields.some(
  field => field.Field === "Gender" && field.IsMandatory);

  return (
    <>
  <div className="flex flex-col">
  <label htmlFor="genero" className={`w-fit pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-left justify-center translate-x-3 bg-white dark:bg-gxp_dark_3 text-sm font-medium dark:text-white text-gray-700 mb-1 z-10 ${parseInt(cS) ? "OC-bg" : ``}`} >{`${t(`Gender.gender`)}`}<span className="text-gray-400 dark:text-white ml-2"> {isRequired ? `( * )\u00A0` : ``} </span> </label>
  <Select
    suffixIcon={ <ChevronDown className={`text-gray-700 dark:text-white cursor-pointer transition-transform duration-300 ease-in-out ${ isOpen ? "rotate-180" : "" }`} /> }
    onDropdownVisibleChange={(open) => setIsOpen(open)}
    className={`w-full py-2 text-xl rounded-lg border text-gray-700 ${error ? 'border-red-500' : 'border-gray-700 dark:border-white'} ${parseInt(cS) ? "OC-fields-borders OC-bg" : ``} focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out appearance-none`}
    style={{ height: "45.5px" }}
    onChange={(selected) => handleGuestChange(guest.id, "Gender", selected.value) }
    placeholder={ <span className="text-gray-700 dark:text-gray-400"> {`${t(`Gender.select`)}`} </span> }
    optionLabelProp="label"
    popupClassName={`dark:dark`}
    showSearch
    id="tipoDocumento"
    dropdownStyle={{ minWidth: "260px" }}
    labelInValue
    value={guest.data.Gender ? { value: guest.data.Gender, label: getGenderLabel(guest.data.Gender) } : undefined}
    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase()) }
    virtual={false}
    >
          {genderOptions.map(option => (
          <Select.Option key={option.value} value={option.value} label={option.label} className={`dark:dark`}>
            {option.label}
          </Select.Option>
        ))}
    </Select>

  {error && ( <div className="flex mt-1 items-center"> <AlertTriangle className="text-red-500 mr-1" size={16} /> <span className="text-red-500 text-sm">{`${t( `HostForm.fieldRequired` )}`}</span> </div> )}
</div>
</> )
}
