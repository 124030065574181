import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const SystemClock = () => {
  const [time, setTime] = useState(new Date());
  const [t] = useTranslation("global");

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const formatDate = (date) => {
    return new Intl.DateTimeFormat("pt-PT", {
      weekday: "long",
      day: "2-digit",
      month: "long",
      year: "numeric",
    }).format(date);
  };

  const formatTime = (date) => {
    return new Intl.DateTimeFormat("pt-PT", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }).format(date);
  };

  return (
    <div className="flex items-end">
      <div className="text-lg max-sm:text-base font-semibold mr-4">
        {dayjs(time).format(t("dayjs_format_header"))}
      </div>
      <div className="text-lg max-sm:text-base font-semibold">{formatTime(time)}</div>
    </div>
  );
};
