/**
 * Function that send a request to API to get hotel info
 * @param {string} hotelId First 8 digits from hotel PmsiD
 * @returns a hotel info
 */
export async function getHotelById(hotelId){
    const requestOptions = {
        method:"GET",
        headers:{"Content-Type":"application/json"}
    }
    const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/hotels/${hotelId}`, requestOptions)
    if(!response.ok){
        throw {message: response.statusText, status: response.status}//eslint-disable-line
    }
    const data = await response.json()
    return data
}

/**
 * Function that send a request to API to get hotel info
 * @param {string} PmsIdPrefix First 8 digits from hotel PmsiD
 * @returns a list of hotels. If hotel has GroupId, returns all hotels from group. If GroupId is null, returns a list of one hotel.
 */
export async function getHotelPmsIdPrefix(PmsIdPrefix){
    const requestOptions = {
        method:"GET",
        headers:{"Content-Type":"application/json"}
    }
    const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/hotels?pmsIdPrefix=${PmsIdPrefix}`, requestOptions)
    if(!response.ok){
        throw {message: response.statusText, status: response.status}//eslint-disable-line
    }
    const data = await response.json()
    return data
}

/**
 * Function that send a request to API to get hotel initialData like documentTypes, Languages, etc
 * @param {object} hotel
 * @returns hotel initialData
 */
export async function getHotelInitialData(hotel, cultureCode){
    const requestOptions = {
        method:"GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers:{"Content-Type":"application/json",
                 "Ocp-Apim-Subscription-Key": hotel.subscriptionKey,}
    }

    const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/gxplocal/bridge/Gxp/Onlinecheckin/InitialData?cultureCode=${cultureCode}`, requestOptions)
    if(!response.ok){
        throw {message: response.statusText, status: response.status}//eslint-disable-line
    }
    const data = await response.json()
    return data
}

export async function getHotelMenu(cultureCode,subscriptionKey){
    const requestOptions = {
        method:"GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers:{"Content-Type":"application/json",
                 "Ocp-Apim-Subscription-Key": subscriptionKey,}
    }
    const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/gxplocal/bridge/Gxp/Menu?cultureCode=${cultureCode}`, requestOptions)
    if(!response.ok){
        throw {message: response.statusText, status: response.status}//eslint-disable-line
    }
    const data = await response.json()
    return data
}

export async function getWebText(cultureCode,key,subscriptionKey){
    const requestOptions = {
        method:"GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers:{"Content-Type":"application/json",
                 "Ocp-Apim-Subscription-Key": subscriptionKey,}
    }
    const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/gxplocal/PmsConfig/WebText/${key}/${cultureCode}`, requestOptions)
    if(!response.ok){
        throw {message: response.statusText, status: response.status}//eslint-disable-line
    }
    const data = await response.json()
    console.log(data)
    return data
}



export async function getHotelTitles(cultureCode,subscriptionKey){
    const requestOptions = {
        method:"GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers:{"Content-Type":"application/json",
                 "Ocp-Apim-Subscription-Key": subscriptionKey,}
    }
    const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/gxplocal/bridge/Gxp/GetTitles?cultureCode=${cultureCode}`, requestOptions)
    if(!response.ok){
        throw {message: response.statusText, status: response.status}//eslint-disable-line
    }
    const data = await response.json()
    return data
}