import { Search, X, Check, AlertTriangle } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const Modal = ({ isOpen, onClose, initialText}) => {
  const [t] = useTranslation("global");


  if (!isOpen) return null;

  return (
    <AnimatePresence>
      <motion.div
        className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <motion.div
          className="bg-white dark:bg-gxp_dark_3 rounded-lg shadow-lg p-4 max-w-md w-full mx-4" initial={{ scale: 0.9, opacity: 0 }} animate={{ scale: 1, opacity: 1 }} exit={{ scale: 0.9, opacity: 0 }} >
          <div className="flex items-center mb-6 justify-end">
            <button type='button' onClick={onClose} className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-white" >
              <X size={20} />
            </button>
          </div>

          <div className="mb-6" dangerouslySetInnerHTML={{ __html: initialText }} >
          </div>

          <div className="flex justify-end">
            <button type="button" onClick={onClose} className="flex items-center gap-2 bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600" >
              <Check size={20} />
              {`${t(`Consent.back`)}`}
            </button>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};