import { useTranslation } from "react-i18next";
import React from "react";
import { AlertTriangle } from "lucide-react";

/**
 * @module FirstName
 * @description Component for entering a guest's first name.
 * This component includes an input field for the first name entry
 * and handles its change through the provided props.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {function} props.handleGuestChange - Function to handle changes in guest data.
 * @param {Object} props.guest - The guest object containing first name data.
 * @param {Array<Object>} props.requiredFields - List of required fields for validation,
 *                                               where each object contains:
 *                                               - {string} Field - The field name.
 *                                               - {boolean} IsMandatory - Indicates if the field is required.
 *
 * @returns {JSX.Element} The rendered first name input field.
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 *
 * @example
 * // Example usage of the FirstName component
 * <FirstName
 *   handleGuestChange={handleGuestChange}
 *   guest={guestData}
 *   requiredFields={requiredFields}
 * />
 */
export const FirstName = ({cS, handleGuestChange, guest, requiredFields, error }) => {
  const [t] = useTranslation("global");

    // Check if the FirstName field is required based on the provided validation rules
    const isRequired = requiredFields.some(
      field => field.Field === "FirstName" && field.IsMandatory);

  return (
    <div>
      <label required={isRequired} className={`pl-2 rounded-xl inline-block translate-y-3.5 text-center justify-center translate-x-3 bg-white dark:bg-gxp_dark_3 text-sm font-medium text-gray-700 dark:text-white mb-1 ${parseInt(cS) ? "OC-bg" : ``}`} >{`${t(`FirstName.firstname`)}`}<span className="text-gray-400 dark:text-white ml-2">{isRequired?`( * )\u00A0`: ``}</span></label>
      <input name="FirstName" onChange={(e)=>handleGuestChange(guest.id,e.target.name,e.target.value)} value={guest.data.FirstName || ""} type="text" id="nome" className={`dark:bg-gxp_dark_3 dark:text-white w-full px-4 py-2 text-xl rounded-lg border ${error ? 'border-red-500' : 'border-gray-700 dark:border-white'} ${parseInt(cS) ? "OC-fields-borders OC-bg" : ``} focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out`} />
      {error && <div className="flex mt-1 items-center"> <AlertTriangle className="text-red-500 mr-1" size={16} /> <span className="text-red-500 text-sm">{`${t(`HostForm.fieldRequired`)}`}</span> </div>}
    </div>)
}
