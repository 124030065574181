import { useContext, useEffect, useState } from "react";
import { getEMenuDetails } from "../../services";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import { organizeMenuData } from "./OrganizeMenuData";
import { MenuLayout } from "./MenuLayout";
import { MenuDetail } from "./MenuDetail";
import { MenuHome } from "./MenuHome";
import { findItemById } from "./findItemById";
import { Loader } from "../../components/Layout/components/Loader/Loader";
import { searchInItems } from "./searchInItems";
import { MenuItem } from "./MenuItem";
import { AppContext } from "../../context/AppContext";
import { Modal } from "./Modal";

export const EMenu = () => {
  const [serverTime, setServerTime] = useState();
  const { key, itemId } = useParams();
  const [t] = useTranslation("global");
  const [cookie, setCookie] = useCookies(["currentHotel", "cultureCode","platformUid", "detailId", "sUid"]); // eslint-disable-line
  const [isLoading, setIsLoading] = useState(true);
  const [service, setService] = useState([]);
  const [organized, setOrganized] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [happyHour, setHappyHour] = useState()
  const [userLanguage, setUserLanguage] = useState(localStorage.getItem("userLanguage"))
  const [state, dispatch] = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(true);

  // Function to update server time
  const updateServerTime = (initialTime) => {
    const currentServerTime = new Date(initialTime);
    currentServerTime.setMinutes(currentServerTime.getMinutes() + 1);
    setServerTime(currentServerTime.toISOString());
  };

  useEffect(() => {
    setUserLanguage(localStorage.getItem("userLanguage"))
  }, [localStorage.getItem("userLanguage")])

  const loadEmenuDetails = async () => {
    if (key && cookie.currentHotel && userLanguage) {
      try {
        setIsLoading(true);
        const details = await getEMenuDetails( key, userLanguage, cookie.currentHotel );
        setServerTime(details.ServerTime);
        setService(details);
        if(details.HappyHours.length>0){
          setHappyHour(details.HappyHours)
        }
      } catch (error) {
        console.error("Error loading EMenu Headers:", error);
        toast.error(t("OnlineCheckIn.errorhost"));
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    loadEmenuDetails();
  }, [key, cookie.currentHotel, userLanguage]);

  useEffect(() => {
    if (service?.MenuDetails) {
      const routeParams = { platformUid: cookie.platformUid, detailId: cookie.detailId, sUid: cookie.sUid, key: key };

      const organizedData = organizeMenuData( service.MenuDetails, service.Allergens, service.Messages, routeParams );
      setOrganized(organizedData);
    }
  }, [service, cookie.platformUid, cookie.detailId, cookie.sUid, key]);

  // Effect to update server time every minute
  useEffect(() => {
    if (!serverTime) return;
    const interval = setInterval(() => {
      updateServerTime(serverTime);
    }, 60000); // Update every minute
    return () => clearInterval(interval);
  }, [serverTime]);

  // Função recursiva para processar um item e seus children
  const processItemAndChildrenDiscount = (item, discountItem) => {
    // Processa o item atual
    if (item.ItemId === discountItem.I && item.AllPrices) {
      try {
        const priceEntries = item.AllPrices.split(';');
        const currentPriceEntry = priceEntries.find(p => p.startsWith(`${item.PriceLevel}:`));

        if (!currentPriceEntry) return;

        const originalPrice = parseFloat(currentPriceEntry.split(':')[1] || '0');
        if (isNaN(originalPrice)) return;

        const discountPercent = discountItem.Dv;
        const discountedPrice = originalPrice * (1 - discountPercent / 100);

        // Apenas adiciona a propriedade hh com o preço com desconto
        item.hh = discountedPrice.toFixed(2);
      } catch (error) {
        console.warn('Error processing happy hour price for item:', item.Id, error);
      }
    }

    // Processa recursivamente todos os children
    if (item.Children && Array.isArray(item.Children)) {
      item.Children.forEach(child => processItemAndChildrenDiscount(child, discountItem));
    }
  };

  const processItemAndChildrenFixedPrice = (item, happyHourPrice) => {
    // Processa o item atual
    if (item.AllPrices) {
      try {
        const priceEntries = item.AllPrices.split(';');

        // Encontra o preço do happy hour
        const fixedPriceEntry = priceEntries.find(p => p.startsWith(`${happyHourPrice}:`));

        // Encontra o preço inicial (baseado no PriceLevel do item)
        const currentPriceEntry = priceEntries.find(p => p.startsWith(`${item.PriceLevel}:`));

        if (fixedPriceEntry && currentPriceEntry) {
          const fixedPrice = parseFloat(fixedPriceEntry.split(':')[1] || '0');
          const currentPrice = parseFloat(currentPriceEntry.split(':')[1] || '0');

          // Só adiciona hh se os preços forem diferentes
          if (!isNaN(fixedPrice) && !isNaN(currentPrice) && fixedPrice !== currentPrice) {
            item.hh = fixedPrice.toFixed(2);
          }
        }
      } catch (error) {
        console.warn('Error processing fixed happy hour price for item:', item.Id, error);
      }
    }

    // Processa recursivamente todos os children
    if (item.Children && Array.isArray(item.Children)) {
      item.Children.forEach(child => processItemAndChildrenFixedPrice(child, happyHourPrice));
    }
  };

  useEffect(() => {
    if (!happyHour || !organized || happyHour.length === 0 || !serverTime) return;

    const activeHappyHour = happyHour[0];

    // Verifica se a happy hour está ativa
    const currentTime = new Date(serverTime);
    const fromHour = new Date(activeHappyHour.FromHour);
    const toHour = new Date(activeHappyHour.ToHour);
    // Ajusta o dia da semana para começar em Segunda = 0
    // let currentDay = currentTime.getDay() - 1;
    // if (currentDay === -1) currentDay = 6; // Se for Domingo (0-1 = -1), ajusta para 6

    // Ajusta os horários para comparar apenas horas e minutos
    const currentTimeMs = currentTime.getHours() * 60 * 60 * 1000 + currentTime.getMinutes() * 60 * 1000;
    const fromHourMs = fromHour.getHours() * 60 * 60 * 1000 + fromHour.getMinutes() * 60 * 1000;
    const toHourMs = toHour.getHours() * 60 * 60 * 1000 + toHour.getMinutes() * 60 * 1000;



    // Verifica se está dentro do horário e dia correto
    const isActive = currentTimeMs >= fromHourMs &&
                    currentTimeMs <= toHourMs;

    if (!isActive) return;

    if (activeHappyHour.IsTypeDiscount) {
      // Lógica de desconto percentual
      activeHappyHour.DiscountItems.forEach(discountItem => {
        organized.forEach(item => processItemAndChildrenDiscount(item, discountItem));
      });
    } else {
      // Lógica de preço fixo
      organized.forEach(item => processItemAndChildrenFixedPrice(item, activeHappyHour.Price));
    }
  }, [happyHour, organized, serverTime]);

  // Filtrar conteúdo baseado na pesquisa se necessário
  const filteredData = organized;
  // Procura o item em toda a estrutura de dados
  const currentCategory = itemId ? findItemById(organized, itemId) : null;

  const searchData = searchInItems(organized, searchText)

   if (isLoading) {
     return <div className="flex justify-center h-screen dark:text-white dark:bg-gxp_dark_2"><Loader className={"w-20 h-20 mt-20"}/></div>;
   }

  if (!service) return null;

  return (
    <>
    <MenuLayout searchText={searchText} onSearchChange={setSearchText} className={"bg-white dark:bg-gxp_dark_2"} service={service} >
      {searchData.length>0?
        <div className="space-y-3 p-2">
          {searchData?.map((item) => (
            <MenuItem key={item.Id} item={item} service={service} />
          ))}
        </div>
      :
      (itemId ? (
        <MenuDetail category={currentCategory} serverTime={serverTime} service={service}  />
      ) : (
        <MenuHome categories={filteredData} />
      ))}
    </MenuLayout>
    { service.HasInitialText &&
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} initialText={service.InitialText} />
    }
      </>

  );};
