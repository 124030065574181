import { useTranslation } from "react-i18next";
import { AlertTriangle } from "lucide-react";

/**
 * @module DocumentNumber
 * @description Component for managing the document number of a guest.
 * This component includes an input field for entering the document number,
 * with validation based on required fields.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {function} props.handleGuestChange - Function to handle changes in guest data.
 * @param {Object} props.guest - The guest object containing document number data.
 * @param {Array<Object>} props.requiredFields - List of required fields for validation,
 *                                               where each object contains:
 *                                               - {string} Field - The field name.
 *                                               - {boolean} IsMandatory - Indicates if the field is required.
 *
 * @returns {JSX.Element} The rendered document number input field.
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 *
 * @example
 * // Example usage of the DocumentNumber component
 * <DocumentNumber
 *   handleGuestChange={handleGuestChange}
 *   guest={guestData}
 *   requiredFields={requiredFields}
 * />
 */
export const DocumentNumber = ({handleGuestChange, guest, requiredFields, error, cS}) => {
  const [t] = useTranslation("global");

    // Determine if the document number field is required based on the provided validation rules
    const isRequired = requiredFields.some(
      field => field.Field === "DocNumber" && field.IsMandatory);

  return (
    <div>
    <label htmlFor="numeroDocumento" className={`pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-center justify-center translate-x-3 bg-white dark:bg-gxp_dark_3 text-sm font-medium text-gray-700 dark:text-white mb-1 ${parseInt(cS) ? "OC-bg" : ``}`} >{`${t(`DocumentNumber.documentnumber`)}`}<span className="text-gray-400 dark:text-white ml-2">{isRequired?`( * )\u00A0`: ``}</span></label>
    <input name="DocNumber"required={isRequired}  onChange={(e)=>handleGuestChange(guest.id,e.target.name,e.target.value)} value={guest.data.DocNumber || ""}  type="text" id="numeroDocumento" className={`w-full px-4 ${parseInt(cS) ? "OC-fields-borders OC-bg" : ``} py-2 text-xl rounded-lg dark:bg-gxp_dark_3 dark:text-white border ${error ? 'border-red-500' : 'border-gray-700 dark:border-white'} focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out`} />
    {error && <div className="flex mt-1 items-center"> <AlertTriangle className="text-red-500 mr-1" size={16} /> <span className="text-red-500 text-sm">{`${t(`HostForm.fieldRequired`)}`}</span> </div>}
  </div>  )
}
