import { useParams, useLocation } from "react-router-dom";
import { Navbar, SidebarHotel, Footer } from "../../components";
import { AppContext } from "../../context/AppContext";
import { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { ReservationComponent } from "./components/ReservationComponent";
import { getHotelInitialData, getOutlet } from "../../services";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

/**
 * @module TableReservation
 * @description Main component responsible for rendering the table reservation page.
 * It retrieves service information based on the route parameters and manages the
 * state for the selected service, days in the calendar, and main dial codes.
 * It also includes navigation and layout components for a complete user experience.
 *
 * @returns {JSX.Element} The table reservation component.
 *
 * @example
 * <TableReservation />
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 */
export const TableReservation = () => {
  const { key, sUid } = useParams();
  const [state, dispatch] = useContext(AppContext);
  const [cookie, setCookie] = useCookies(["sUid", "currentHotel"]); // eslint-disable-line
  const [service, setService] = useState([]);
  const [daysInCalendar, setDaysInCalendar] = useState();
  const [mainDialCodes, setMainDialCodes] = useState([]);
  const [withoutReservation, setWithoutReservation] = useState(false);
  const [initialData, setInitialData] = useState();
  const location = useLocation();
  const [t] = useTranslation("global");

  /**
   * @function useEffectSetSUid
   * @description A React effect hook that runs when the component mounts or when the `sUid` value changes.
   * This hook checks if the `sUid` parameter is available and sets it as a cookie named "sUid".
   * The cookie is accessible throughout the entire site (indicated by `path: "/"`).
   *
   * @param {string} sUid - The unique identifier for the user session or specific user.
   *
   * @effect This effect will update the cookie whenever the `sUid` changes.
   * It ensures that the current session identifier is stored for reference in future requests.
   *
   * @dependencies [sUid] - The effect depends on `sUid`, meaning it will run whenever `sUid` changes.
   *
   * @example
   * // Example usage of sUid in useEffect
   * useEffect(() => {
   *   if (sUid) {
   *     setCookie("sUid", sUid, { path: "/" });
   *   }
   * }, [sUid]);
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   */
  useEffect(() => {
    if (sUid) {
      setCookie("sUid", sUid, { path: "/" });
    }
  }, [sUid]);

  /**
   * @function initializeData
   * @description Fetches all necessary data in a single initialization
   */
  const initializeData = async () => {
    if (!key || !cookie.currentHotel) return;

    try {
      // Fetch outlet data
      const outletData = await getOutlet([key], cookie.currentHotel);

      // Only fetch hotel data if not already in state
      let hotelData = state.hotelInitialData;
      if (!hotelData) {
        const hotelResponse = await getHotelInitialData(cookie.currentHotel);
        hotelData = hotelResponse.InitialData;
      }
        setService(outletData.outletInfo[0])
        setDaysInCalendar(outletData.daysInCalendar)
        setMainDialCodes(outletData.mainDialCodes)
        setInitialData(hotelData)
    } catch (error) {
      console.error("Error initializing data:", error);
      toast.error(t("OnlineCheckIn.errorhotel"));
    }
  };

    // Initialize all data on component mount
    useEffect(() => {
      initializeData();
    }, [key, cookie.currentHotel]); // eslint-disable-line

  useEffect(() => {
    // Verifica se o pathname começa com '/overview'
    if (location.pathname.includes("/treservationdirect")) {
      setWithoutReservation(true);
    }
  }, []);


  if (!withoutReservation) {
    return (
      <>
        {service && key && daysInCalendar && cookie.currentHotel && (
          <div className="max-w-2xl mx-auto mt-2">
            {service?.logoUrl ? (
              <div className="relative h-60 w-full overflow-hidden shadow-lg hover:shadow-xl transition-shadow mb-2">
                <img src={service?.logoUrl || "/api/placeholder/400/320"} alt={service.Name} className="w-full h-full object-cover" />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                <div className="absolute bottom-0 left-0 right-0 p-4">
                  <h2 className="text-white text-xl font-semibold">
                    {service.Name}
                  </h2>
                </div>
              </div>
            ) : (
              <div className="relative h-48 w-full overflow-hidden bg-white border-violet-100 dark:bg-gxp_dark_3 dark:border-gray-800 text-gray-600 dark:text-white border-2 rounded-lg shadow-md p-4 mb-4 hover:shadow-lg transition-shadow duration-300">
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                <div className="absolute bottom-0 left-0 right-0 p-4">
                  <h2 className="text-white text-xl font-semibold">
                    {service.Name}
                  </h2>
                </div>
              </div>
            )}
            <section className="flex-grow ">
              <ReservationComponent withoutReservation={withoutReservation} service={service} daysInCalendar={daysInCalendar} mainDialCodes={mainDialCodes} initialData={initialData} />
            </section>
          </div>
        )}
      </>
    );
  }

  if (withoutReservation) {
    return (
      <>
        {service && key && daysInCalendar && cookie.currentHotel && (
          <div className="max-w-4xl mx-auto mt-2">
            {service?.logoUrl ? (
              <div className="relative h-48 w-full overflow-hidden shadow-lg hover:shadow-xl transition-shadow mb-2">
                <img src={service?.logoUrl || "/api/placeholder/400/320"} alt={service.Name} className="w-full h-full object-cover" />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                <div className="absolute bottom-0 left-0 right-0 p-4">
                  <h2 className="text-white text-xl font-semibold">
                    {service.Name}
                  </h2>
                </div>
              </div>
            ) : (
              <div className="relative h-48 w-full overflow-hidden bg-white border-violet-100 dark:bg-gxp_dark_3 dark:border-gray-800 text-gray-600 dark:text-white border-2 rounded-lg shadow-md p-4 mb-4 hover:shadow-lg transition-shadow duration-300">
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                <div className="absolute bottom-0 left-0 right-0 p-4">
                  <h2 className="text-white text-xl font-semibold">
                    {service.Name}
                  </h2>
                </div>
              </div>
            )}
            <section className="flex-grow p-1">
              <ReservationComponent withoutReservation={withoutReservation} service={service} daysInCalendar={daysInCalendar} mainDialCodes={mainDialCodes} initialData={initialData} />
            </section>
          </div>
        )}
      </>
    );
  }
};
