export const findItemById = (items, targetId) => {
    // Função recursiva para procurar em toda a árvore
    const findInTree = (items) => {
      for (const item of items) {
        // Verifica se é o item que procuramos
        if (item.Id.toString() === targetId) {
          return item;
        }

        // Se tiver filhos, procura recursivamente neles
        if (item.Children && item.Children.length > 0) {
          const found = findInTree(item.Children);
          if (found) return found;
        }
      }
      return null;
    };

    return findInTree(items);
  };