import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { Select } from "antd";
import { ChevronDown } from "lucide-react";

export const Phone = ({mainDialCodes, formData, setFormData, initialData }) => {
  const [selectedDialCode, setSelectedDialCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isDialCodeSelected, setIsDialCodeSelected] = useState(false);
  const [t] = useTranslation("global");
  const [cookie, setCookie] = useCookies(["currentHotel"]); // eslint-disable-line
  const [isOpen, setIsOpen] = useState(false);

  const countries = useMemo(() => {
    return initialData?.CountryList?.map(country => ({
      ...country,
      dialCode: initialData.DialCodes[country.IsoCode] || ''
    }));
  }, [initialData?.CountryList, initialData?.DialCodes]);

  const handleDialCodeChange = (selected) => {
    const dialCode = selected.value;
    setSelectedDialCode(dialCode);
    setIsDialCodeSelected(true);
  };

  const dialCodeIndex = mainDialCodes?.reduce((acc, code, index) => {
    acc[code] = index;
    return acc;
  }, {});

  const sortedCountries = useMemo(() => {
    return initialData?.CountryList?.sort((a, b) => {
      const aDialCode = a.IsoCode3LetterDocumentReader;
      const bDialCode = b.IsoCode3LetterDocumentReader;
      const aIndex = dialCodeIndex[aDialCode] !== undefined ? dialCodeIndex[aDialCode] : Infinity;
      const bIndex = dialCodeIndex[bDialCode] !== undefined ? dialCodeIndex[bDialCode] : Infinity;
      return aIndex - bIndex;
    });
  }, [initialData?.CountryList, dialCodeIndex]);

  useEffect(() => {
    setFormData({ ...formData, GuestPhone1: `${selectedDialCode} ${phoneNumber}` });
  }, [phoneNumber, selectedDialCode]);

  return (
    <>
      {initialData && mainDialCodes && (
        <div className="flex w-full">
          <div className="flex w-2/3 items-end">
            <Select
              suffixIcon={
                <ChevronDown
                  className={`text-gray-700 dark:text-white transition-transform duration-300 ease-in-out ${
                    isOpen ? "rotate-180" : ""
                  }`}
                />
              }
              onDropdownVisibleChange={(open) => setIsOpen(open)}
              className={`w-full py-2 text-xl rounded-l-lg border text-gray-700 dark:text-white dark:bg-gxp_dark_3
                focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out appearance-none`}
              style={{ width: "150px", height: "45.5px" }}
              onChange={handleDialCodeChange}
              placeholder={ <span className="text-gray-700 dark:text-gray-400"> {`${t(`Phone.select`)}`} </span> }
              optionLabelProp="label"
              popupClassName={`dark:dark`}
              showSearch
              dropdownStyle={{ minWidth: "260px" }}
              labelInValue
              value={ selectedDialCode ? { value: selectedDialCode, label: ( <div className="flex items-center dark:text-white"> <img src={`https://flagcdn.com/w20/${ countries.find((c) => c.dialCode === selectedDialCode) ?.IsoCode.toLowerCase() }.png`} alt="flag" className="w-6 h-4 mr-2" /> <span className="text-l dark:text-white"> {selectedDialCode} </span> </div> ), } : undefined }
              filterOption={(input, option) => {
                const country = countries.find(
                  (country) => country.dialCode === option.value
                );
                if (!country) return false;
                return country.TranslatedDescription.toLowerCase().includes(
                  input.toLowerCase()
                );
              }}
              virtual={false}
            >
              {sortedCountries.map((country) => (
                <Select.Option
                  key={country.IsoCode}
                  className={`dark:dark`}
                  value={country.dialCode}
                  label={
                    <div className="flex items-center dark:text-white">
                      <img
                        src={`https://flagcdn.com/w20/${country.IsoCode.toLowerCase()}.png`}
                        alt={`${country.TranslatedDescription} flag`}
                        className="w-6 h-4 mr-2"
                      />
                      <span className="text-l dark:text-white">
                        {country.dialCode}
                      </span>
                    </div>
                  }
                >
                  <div className="flex items-center">
                    <img
                      src={`https://flagcdn.com/w20/${country.IsoCode.toLowerCase()}.png`}
                      alt={`${country.TranslatedDescription} flag`}
                      className="w-6 h-4 mr-2"
                    />
                    <span className="text-l dark:text-white">
                      {country.TranslatedDescription}
                    </span>
                    <span className="ml-2 text-l text-gray-500 dark:text-white">
                      {country.dialCode}
                    </span>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="w-full">
            <label
              htmlFor="telefone"
              className="pl-2 pr-2 rounded-xl inline-block translate-y-3.5 translate-x-3 text-center justify-center bg-white dark:bg-gxp_dark_3 text-sm font-medium text-gray-700 dark:text-white mb-1"
            >
              {`${t(`Phone.phonenumber`)}`}
            </label>
            <input
              name="Phone"
              onChange={(e) => setPhoneNumber(e.target.value)}
              value={phoneNumber || ""}
              type="tel"
              id="telefone"
              className="w-full px-4 py-2 rounded-r border border-gray-700 dark:border-white dark:bg-gxp_dark_3 dark:text-white focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-xl transition duration-150 ease-in-out"
            />
          </div>
        </div>
      )}
    </>
  );
};