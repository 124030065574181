import { useTranslation } from "react-i18next";
import { AlertTriangle } from "lucide-react";
import { Select } from "antd";
import { ChevronDown } from "lucide-react";
import { useState } from "react";

/**
 * @module DocumentType
 * @description Component for selecting the type of document for a guest.
 * This component includes a dropdown menu for selecting a document type,
 * with validation based on required fields.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {function} props.handleGuestChange - Function to handle changes in guest data.
 * @param {Object} props.guest - The guest object containing document type data.
 * @param {Array<Object>} props.initialData - Initial data containing available document types,
 *                                            where each object includes:
 *                                            - {string} Id - The unique identifier for the document type.
 *                                            - {string} Description - The name/description of the document type.
 * @param {Array<Object>} props.requiredFields - List of required fields for validation,
 *                                               where each object contains:
 *                                               - {string} Field - The field name.
 *                                               - {boolean} IsMandatory - Indicates if the field is required.
 *
 * @returns {JSX.Element} The rendered document type selection dropdown.
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 *
 * @example
 * // Example usage of the DocumentType component
 * <DocumentType
 *   handleGuestChange={handleGuestChange}
 *   guest={guestData}
 *   initialData={initialData}
 *   requiredFields={requiredFields}
 * />
 */
export const DocumentType = ({ handleGuestChange, guest, initialData, requiredFields, error, cS }) => {
  const [t] = useTranslation("global");
  const [isOpen, setIsOpen] = useState(false);

  // Determine if the document type field is required based on the provided validation rules
  const isRequired = requiredFields.some(
    (field) => field.Field === "DocType" && field.IsMandatory
  );

  return (
    <>
      <div className="flex flex-col">
        <label className={`w-fit pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-left justify-center translate-x-3 bg-white dark:bg-gxp_dark_3 text-sm font-medium dark:text-white text-gray-700 mb-1 z-10 ${parseInt(cS) ? "OC-bg" : ``} `} > {`${t(`DocumentType.documenttype`)}`} <span className="text-gray-400 dark:text-white ml-2"> {isRequired ? `( * )\u00A0` : ``} </span> </label>
        <Select
          suffixIcon={ <ChevronDown className={`text-gray-700 dark:text-white cursor-pointer transition-transform duration-300 ease-in-out ${ isOpen ? "rotate-180" : "" }`} /> }
          onDropdownVisibleChange={(open) => setIsOpen(open)}
          className={`w-full py-2 text-xl rounded-lg border text-gray-700 ${error ? 'border-red-500' : 'border-gray-700 dark:border-white'} ${parseInt(cS) ? "OC-fields-borders OC-bg" : ``} focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out appearance-none`}
          style={{ height: "45.5px" }}
          onChange={(selected) => handleGuestChange(guest.id, "DocType", selected.value) }
          placeholder={ <span className="text-gray-700 dark:text-gray-400"> {`${t(`DocumentType.select`)}`} </span> }
          optionLabelProp="label"
          popupClassName={`dark:dark`}
          showSearch
          dropdownStyle={{ minWidth: "260px" }}
          labelInValue
          value={ guest.data.DocType ? { value: guest.data.DocType, label: initialData.GuestDocumentTypes.find( (doc) => doc.Id === guest.data.DocType )?.Description, } : undefined }
          filterOption={(input, option) => { return option.children.toLowerCase().includes(input.toLowerCase()); }}
          virtual={false}
        >
          {initialData.GuestDocumentTypes.map((document) => (
            <Select.Option key={document.Id} value={document.Id} label={document.Description} className={`${parseInt(cS) ? "OC-bg" : ``} dark:dark`} >
              {document.Description}
            </Select.Option>
          ))}
        </Select>
        {error && ( <div className="flex mt-1 items-center"> <AlertTriangle className="text-red-500 mr-1" size={16} /> <span className="text-red-500 text-sm">{`${t( `HostForm.fieldRequired` )}`}</span> </div> )}
      </div>
    </>
  );
};
