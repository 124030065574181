import { useTranslation } from "react-i18next";
import { Phone } from "./FormFields/Phone";

/**
 * @module SubmissionForm
 * @description Renders a submission form for table reservations, allowing
 * users to input their reservation details, including guest information,
 * and contact details. This form validates inputs and uses the provided
 * handler function to submit the data.
 *
 * @param {Object} props - The component properties.
 * @param {Array} props.mainDialCodes - Array of main dialing codes for phone
 * number input.
 * @param {function} props.handleSubmit - Function to handle the form submission.
 * @param {Object} props.fadeVariants - Animation variants for fade effects.
 * @param {number} props.people - Number of guests for the reservation.
 * @param {Date} props.date - Selected reservation date.
 * @param {string} props.time - Selected reservation time.
 * @param {Object} props.formData - Object containing form data fields.
 * @param {function} props.setFormData - Function to update the form data state.
 * @param {function} props.getEndTime - Function to calculate the end time
 * based on the start time.
 * @param {Object} props.service - Object containing details of the service.
 * @param {string} props.title - Title to represent the reservation.
 * @param {function} props.setTitle - Function to update the title state.
 *
 * @returns {JSX.Element} The submission form component for reservations.
 *
 * @example
 * <SubmissionForm
 *   mainDialCodes={dialCodes}
 *   handleSubmit={submitHandler}
 *   fadeVariants={variants}
 *   people={4}
 *   date={new Date()}
 *   time="18:00"
 *   formData={formData}
 *   setFormData={setFormData}
 *   getEndTime={getEndTime}
 *   service={serviceDetails}
 *   title={reservationTitle}
 *   setTitle={setReservationTitle}
 * />
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 */
export const SubmissionForm = ({ mainDialCodes, people, date, time, formData, setFormData, getEndTime, service, title, setTitle, initialData }) => {
  const [t] = useTranslation("global");

  return (
    <> {service && mainDialCodes &&
    <>
      <h2 className="text-xl mb-4 dark:text-white">{`${t(`SubmissionForm.reservationdetails`)}`}</h2>
      <div className="mb-4 dark:text-white">
        <p>{`${people} ${t(`SubmissionForm.guests`)} | ${date.toLocaleDateString("pt-PT", { weekday: "long", day: "2-digit", month: "2-digit", })} | ${time}`}</p>
      </div>
      <div className="max-w-full">
        <div className="flex space-x-4 font-medium text-gray-700 dark:text-white">
          {[t(`SubmissionForm.mr`),t(`SubmissionForm.mrs`), t(`SubmissionForm.other`)].map((option) => (
            <label key={option} className="flex items-center">
              <input type="radio" required name="title" value={option} checked={title === option} onChange={(e) => setTitle(e.target.value ) } className="mr-2" />
              {option}
            </label>
          ))}
        </div>
        <div className="sm:flex sm:flex-wrap sm:space-x-2 max-w-full">
          <div className="sm:w-[calc(50%-0.25rem)]">
            <label htmlFor="firstName" className="pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-center justify-center translate-x-3 bg-white dark:bg-gxp_dark_3 text-sm font-medium text-gray-700 dark:text-white mb-1" >{`${t(`FirstName.firstname`)}`}</label>
            <input name="firstName" required id="firstName" type="text" value={formData.FirstName} onChange={(e) => setFormData({ ...formData, FirstName: e.target.value }) } className="max-w-full p-2 border rounded max-sm:w-full border-gray-700 dark:border-white dark:bg-gxp_dark_3 dark:text-white focus:ring-2 text-xl focus:border-blue-500 transition duration-150 ease-in-out"  />
          </div>
          <div className="sm:w-[calc(50%-0.25rem)]">
            <label htmlFor="lastName" className="pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-center justify-center translate-x-3 bg-white dark:bg-gxp_dark_3 text-sm font-medium text-gray-700 dark:text-white mb-1" >{`${t(`LastName.lastname`)}`}</label>
            <input name="lastName" required id="lastName" type="text" value={formData.LastName} onChange={(e) => setFormData({ ...formData, LastName: e.target.value }) } className="max-w-full p-2 border rounded max-sm:w-full border-gray-700 dark:border-white dark:bg-gxp_dark_3 dark:text-white focus:ring-2 text-xl focus:border-blue-500 transition duration-150 ease-in-out"  />
          </div>
        </div>
        <div>
          <label htmlFor="email" className="pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-center justify-center translate-x-3 bg-white dark:bg-gxp_dark_3 text-sm font-medium text-gray-700 dark:text-white mb-1" >{`${t(`Email.email`)}`}</label>
          <input type="email" required value={formData.GuestEmail1} onChange={(e) => setFormData({ ...formData, GuestEmail1: e.target.value })} className="w-full p-2 border rounded max-sm:w-full border-gray-700 dark:border-white dark:bg-gxp_dark_3 dark:text-white focus:ring-2 text-xl focus:border-blue-500 transition duration-150 ease-in-out"  />
        </div>
        <Phone mainDialCodes={mainDialCodes} formData={formData} setFormData={setFormData} initialData={initialData} required />
        <div>
          <label htmlFor="email" className="pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-center justify-center translate-x-3 bg-white dark:bg-gxp_dark_3 text-sm font-medium text-gray-700 dark:text-white mb-1" >Observações / Comentários<span className="text-gray-400 dark:text-white ml-2">{`${t(`FormFields.optional`)}`}</span></label>
          <textarea value={formData.Notes} onChange={(e) => setFormData({ ...formData, Notes: e.target.value }) } className="w-full p-2 border rounded h-24 resize-none max-sm:w-full border-gray-700 dark:border-white dark:bg-gxp_dark_3 dark:text-white focus:ring-2 text-xl focus:border-blue-500 transition duration-150 ease-in-out" />
        </div>
      </div>
      <div className="mt-4 text-sm text-gray-600 dark:text-white ">
        <p><strong>{`${t(`ReservationComponent.duration`)}`}</strong>{` ${t(`ReservationComponent.yourtable`)} ${time} ${t(`ReservationComponent.to`)} ${getEndTime(time)}.`}</p>
      </div>
      </>}
    </>
  );
};
