import { Select } from 'antd';
import dayjs from 'dayjs';
import { AlertTriangle, ChevronDown} from 'lucide-react';
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';

export const DateTimePicker = ({schedules, scheduleExceptions, itemData, formData, setFormData, fieldErrors, setFieldErrors, selections, setSelections }) => {
    const [availableTimes, setAvailableTimes] = useState([]);
    const [isDateOpen, setIsDateOpen] = useState(false);
    const [isTimeOpen, setIsTimeOpen] = useState(false);
    const [t] = useTranslation("global");
    const itemRef = useRef(null);
    const datePickerRef = useRef(null);
    const timePickerRef = useRef(null);

    useEffect(() => {
        if (isDateOpen || isTimeOpen) {
            document.body.classList.add("no-scroll");
        } else {
          document.body.classList.remove("no-scroll");
        }
        return () => {
          document.body.classList.remove("no-scroll");
        };
      }, [isDateOpen, isTimeOpen]);

    // Gera array de datas disponíveis (hoje + 15 dias)
    const getAvailableDates = () => {
      const dates = [];
      const today = new Date();

      for (let i = 0; i < 16; i++) {
        const date = new Date(today);
        date.setDate(today.getDate() + i);
        dates.push(date);
      }
      return dates;
    };

    // Função que gera horários em intervalos de 30 minutos
    const generateTimeSlots = (start, end) => {
      const times = [];
      let current = new Date(`2000-01-01T${start}`);
      const endTime = new Date(`2000-01-01T${end}`);

      while (current <= endTime) {
        times.push(current.toLocaleTimeString('pt-PT', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        }));
        current.setMinutes(current.getMinutes() + 30);
      }
      return times;
    };

    // Verifica se uma data está nas exceções
    const isDateInExceptions = (date) => {
      return scheduleExceptions.some(exception => {
        const exceptionStart = new Date(exception.FromDate);
        const exceptionEnd = new Date(exception.ToDate);
        const dateToCheck = new Date(date);
        dateToCheck.setHours(0, 0, 0, 0);
        return dateToCheck >= exceptionStart && dateToCheck <= exceptionEnd;
      });
    };

    // Processa os horários disponíveis quando a data é selecionada
    const processAvailableTimes = (date) => {
      if (!date) return [];

      const dayOfWeek = new Date(date).getDay();

      // Obtém horários do estabelecimento
      const establishmentSchedule = schedules.find(s => s.Day === dayOfWeek);
      if (!establishmentSchedule) return [];

      let start = establishmentSchedule.FromHour.slice(11, 16);
      let end = establishmentSchedule.ToHour.slice(11, 16);

      // Se o item tiver horários específicos, aplica a restrição
      if (itemData.Schedule) {
        const itemScheduleStr = itemData.Schedule.split(';')
          .find(s => s.startsWith(`${dayOfWeek}_`));

        if (itemScheduleStr) {
          const [, itemStart, itemEnd] = itemScheduleStr.split('_');
          // Atualiza start/end apenas se houver restrição do item
          start = itemStart > start ? itemStart : start;
          end = itemEnd < end ? itemEnd : end;
        }
      }

      // Verifica se a data selecionada é hoje
      const today = new Date();
      const selectedDate = new Date(date);
      const isToday = selectedDate.toDateString() === today.toDateString();
      let times = generateTimeSlots(start, end);

      // Se for hoje, remove horários já passados
      if (isToday) {
        const now = new Date();
        times = times.filter(time => {
          const [hours, minutes] = time.split(':');
          const timeDate = new Date();
          timeDate.setHours(parseInt(hours), parseInt(minutes), 0, 0);
          return timeDate > now;
        });
      }
      return times;
    };

    useEffect(() => {
        setFormData({ ...formData, StartDate: selections.date + " " + selections.time+(selections.time&&":00") });
    }, [selections]);

    useEffect(() => {
      if (selections.date) {
        const times = processAvailableTimes(selections.date);
        setAvailableTimes(times);
        setSelections({ ...selections, time: undefined })
      }
    }, [selections.date]);

  //   useEffect(() => {
  //     const handleOutsideInteraction = (event) => {
  //         // Ignora eventos de scroll
  //         if (event.type === 'scroll') return;

  //         const target = event.target || event.changedTouches?.[0];
  //         if (!datePickerRef.current?.contains(target)) {
  //             setIsDateOpen(false);
  //         }
  //     };

  //     document.addEventListener('mousedown', handleOutsideInteraction);
  //     document.addEventListener('touchend', handleOutsideInteraction); // Usar touchend em vez de touchstart

  //     return () => {
  //         document.removeEventListener('mousedown', handleOutsideInteraction);
  //         document.removeEventListener('touchend', handleOutsideInteraction);
  //     };
  // }, []);

  return (
    <div>
        <div className="w-full max-w-md grid grid-cols-2 gap-2" ref={datePickerRef}>
            <div className="flex flex-col ">
                <label htmlFor="genero" className={`w-fit pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-left justify-center translate-x-3 bg-white dark:bg-gxp_dark_3 text-sm font-medium dark:text-white text-gray-700 mb-1 z-10`} > Data </label>
                <Select
                    suffixIcon={ <ChevronDown className={`text-gray-700 dark:text-white cursor-pointer transition-transform duration-300 ease-in-out ${ isDateOpen ? "rotate-180" : "" }`} /> }
                    className={`w-full py-2 text-xl rounded-md border text-gray-700 focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out appearance-none ${fieldErrors.Date ? 'border-red-500' : 'border-gray-700 dark:border-white'}`}
                    style={{ height: "45.5px" }}
                    onChange={(selected) => {
                        setSelections({
                            date: selected,
                            time: undefined});
                            setFieldErrors((prevErrors) => {
                                const newErrors = { ...prevErrors };
                                delete newErrors["Date"];
                                return newErrors;
                            });
                    }}
                    placeholder={ <span className="text-gray-700 dark:text-gray-400 text-sm">{`${t( `WellnessFormModal.selectday` )}`}</span> }
                    onClick={() => setIsDateOpen(!isDateOpen)}
                    optionLabelProp="label"
                    popupClassName={`dark:dark`}
                    id="date"
                    inputMode="none"
                    dropdownStyle={{ minWidth: "250px", fit: "contain" }}
                    value={selections.date}
                    virtual={false}
                    onMouseDown={e => e.preventDefault()}
                    onTouchStart={e => e.stopPropagation()}
                    onTouchMove={e => e.stopPropagation()}
                    onTouchEnd={e => e.stopPropagation()}
                    >
                    {getAvailableDates().map(date => {
                    const dateStr = date.toISOString().split('T')[0];
                    const isDisabled = isDateInExceptions(date);
                    if (!isDisabled) {
                    return (
                        <Select.Option key={dateStr} value={dateStr} className="dark:dark" >
                          <div ref={itemRef}>
                            {dayjs(date).format(t("dayjs_format_long")) }
                          </div>
                        </Select.Option>
                    );
                    }
                    return null;
                })}
                </Select>
            </div>
            <div className="flex flex-col">
                <label htmlFor="genero" className={`w-fit pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-left justify-center translate-x-3 bg-white dark:bg-gxp_dark_3 text-sm font-medium dark:text-white text-gray-700 mb-1 z-10`} > Hora </label>
                <Select
                    suffixIcon={ <ChevronDown className={`text-gray-700 dark:text-white cursor-pointer transition-transform duration-300 ease-in-out ${ isTimeOpen && selections.date ? "rotate-180" : "" }`} /> }
                    className={`w-full py-2 text-xl rounded-md border text-gray-700 focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out appearance-none ${fieldErrors.Time ? 'border-red-500' : 'border-gray-700 dark:border-white'}`}
                    style={{ height: "45.5px" }}
                    onChange={(selected) => {
                        if (selected) {
                            setSelections({ ...selections, time: selected });
                            setFieldErrors((prevErrors) => {
                                const newErrors = { ...prevErrors };
                                delete newErrors["Time"];
                                return newErrors;
                            });
                        }
                    }}
                    placeholder={ <span className="text-gray-700 dark:text-gray-400 text-sm">{`${t( `WellnessFormModal.selecthour` )}`}</span> }
                    optionLabelProp="label"
                    popupClassName={`dark:dark`}
                    onClick={() => setIsTimeOpen(!isTimeOpen)}
                    id="date"
                    disabled={!selections.date}
                    inputMode="none"
                    dropdownStyle={{ minWidth: "150px" }}
                    value={selections.time}
                    virtual={false}
                    onMouseDown={e => e.preventDefault()}
                    onTouchStart={e => e.stopPropagation()}
                    onTouchMove={e => e.stopPropagation()}
                    onTouchEnd={e => e.stopPropagation()}
                    >
                    {availableTimes.map(time => (
                        <Select.Option key={time} value={time} className="dark:dark" >
                        {time}
                        </Select.Option>
                    ))}
                </Select>
            </div>
        </div>
        {(fieldErrors.Date || fieldErrors.Time) && <div className="flex mt-1 items-center"> <AlertTriangle className="text-red-500 mr-1" size={16} /> <span className="text-red-500 text-sm">{`${t(`HostForm.fieldRequired`)}`}</span> </div>}
      </div>
  );
}




