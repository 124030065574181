import React from "react";
import { MenuItem } from "./MenuItem";

export const MenuDetail = ({ category, service }) => {

  if (!category) return null;
  if (!service) return null;

  return (
    <div className="max-w-4xl mx-auto mt-2">

      {category?.ImageUrl?
      <div className="relative h-48 w-full overflow-hidden shadow-lg hover:shadow-xl transition-shadow mb-2">
        <img src={category?.ImageUrl || "/api/placeholder/400/320"} alt={category.Description} className="w-full h-full object-cover" />
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
        <div className="absolute bottom-0 left-0 right-0 p-4">
          <h2 className="text-white text-xl font-semibold">
            {category.Description}
          </h2>
          {category.Notes && (
            <p className="text-white/80 text-sm mt-1">{category.Notes}</p>
          )}
        </div>
      </div>
      :
      <div className="relative h-48 w-full overflow-hidden bg-white border-violet-100 dark:bg-gxp_dark_3 dark:border-gray-800 text-gray-600 dark:text-white border-2 rounded-lg shadow-md p-4 mb-4 hover:shadow-lg transition-shadow duration-300">
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
        <div className="absolute bottom-0 left-0 right-0 p-4">
          <h2 className="text-white text-xl font-semibold">
            {category.Description}
          </h2>
          {category.Notes && (
            <p className="text-white/80 text-sm mt-1">{category.Notes}</p>
          )}
        </div>
      </div>}

      <div className="space-y-3 p-2">
        {category.Children?.map((item) => (
          <MenuItem key={item.Id} item={item} service={service}  />
        ))}
      </div>
    </div>
  );
};
