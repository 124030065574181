import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import global_en_gb from "../../../translations/en_gb/global.json";
import global_pt_pt from "../../../translations/pt_pt/global.json";

const formatLanguage = (language) => {
  try {
    if (typeof language === 'string') {
      return language?.replace("-", "_");
    }
    return JSON.parse(language)?.replace("-", "_");
  } catch (error) {
    return language?.replace("-", "_");
  }
};

// Configuração do i18next
const i18nextConfig = {
  interpolation: { escapeValue: true },
  fallbackLng: 'en_US',
  resources: {
    en_US: {
      global: global_en_gb,
    },
    en_GB: {
      global: global_en_gb,
    },
    pt_PT: {
      global: global_pt_pt,
    },
  },
};

// Inicialização do i18next
i18next.use(initReactI18next).init({
  ...i18nextConfig,
  lng: formatLanguage(localStorage.getItem('userLanguage') || 'en-US'),
});

// Função para atualizar o idioma
export const updateI18nLanguage = (newLanguage) => {
  const formattedLanguage = formatLanguage(newLanguage);
  i18next.changeLanguage(formattedLanguage);
};

export const configureI18n = () => i18next;