import React, { useState, useRef, useEffect } from 'react';
import { createWorker } from 'tesseract.js';
import { Camera, X, RefreshCw, Search, Loader } from 'lucide-react';

export const DocumentScanner = () => {
  const [isCameraActive, setIsCameraActive] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [ocrResult, setOcrResult] = useState('');
  const [error, setError] = useState(null);
  const videoRef = useRef(null);
  const streamRef = useRef(null);

  useEffect(() => {
    return () => {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  const startCamera = async () => {
    setIsCameraActive(true)
    setError(null);
    try {
      if (!navigator.mediaDevices?.getUserMedia) {
        throw new Error('Seu navegador não suporta acesso à câmera');
      }

      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: 'environment',
          width: { ideal: 1280 },
          height: { ideal: 720 }
        },
        audio: false
      });

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        streamRef.current = stream;
        await videoRef.current.play();
        setIsCameraActive(true);
      }
    } catch (err) {
      console.error('Erro ao acessar a câmera:', err);
      setError(err.message || 'Não foi possível acessar a câmera. Verifique as permissões.');
    }
  };

  const stopCamera = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
      streamRef.current = null;
    }
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
    setIsCameraActive(false);
    setCapturedImage(null);
    setError(null);
  };

  const captureImage = () => {
    if (!videoRef.current) return;

    try {
      const canvas = document.createElement('canvas');
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(videoRef.current, 0, 0);
      const imageDataUrl = canvas.toDataURL('image/jpeg', 0.8);
      setCapturedImage(imageDataUrl);
      setIsCameraActive(false);
      // stopCamera();
    } catch (err) {
      console.error('Erro ao capturar imagem:', err);
      setError('Erro ao capturar imagem. Tente novamente.');
    }
  };

  const processOCR = async () => {
    setIsProcessing(true);
    setError(null);
    try {
      const worker = await createWorker();
      await worker.loadLanguage('por');
      await worker.initialize('por');
      const { data: { text } } = await worker.recognize(capturedImage);
      setOcrResult(text);
      await worker.terminate();
    } catch (err) {
      console.error('Erro no processamento OCR:', err);
      setError('Erro ao processar o texto da imagem.');
    }
    setIsProcessing(false);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white dark:bg-gray-800 rounded-lg w-full max-w-2xl p-4">
        {error && (
          <div className="mb-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded-lg">
            {error}
          </div>
        )}

        {!isCameraActive && !capturedImage && (
          <div className="flex justify-center">
            <button onClick={startCamera} className="flex items-center gap-2 bg-blue-500 hover:bg-blue-600 text-white px-6 py-3 rounded-lg transition-colors" >
              <Camera size={24} />
              Ativar Câmera
            </button>
          </div>
        )}

        {isCameraActive && (
          <div className="space-y-4">
            <div className="relative h-[400px] bg-black rounded-lg overflow-hidden">
              <video ref={videoRef} autoPlay playsInline muted className="absolute inset-0 w-full h-full object-contain" />
            </div>
            <div className="flex justify-center gap-4">
              <button onClick={stopCamera} className="flex items-center gap-2 bg-red-500 hover:bg-red-600 text-white px-6 py-3 rounded-lg transition-colors" >
                <X size={24} />
                Cancelar
              </button>
              <button onClick={captureImage} className="flex items-center gap-2 bg-green-500 hover:bg-green-600 text-white px-6 py-3 rounded-lg transition-colors" >
                <Camera size={24} />
                Capturar
              </button>
            </div>
          </div>
        )}

        {capturedImage && !isProcessing && (
          <div className="space-y-4">
            <div className="relative h-[400px] bg-black rounded-lg overflow-hidden">
              <img
                src={capturedImage}
                alt="Documento capturado"
                className="absolute inset-0 w-full h-full object-contain"
              />
            </div>
            <div className="flex justify-center gap-4">
              <button
                onClick={() => {
                  setCapturedImage(null);
                  startCamera();
                }}
                className="flex items-center gap-2 bg-red-500 hover:bg-red-600 text-white px-6 py-3 rounded-lg transition-colors"
              >
                <RefreshCw size={24} />
                Nova Foto
              </button>
              <button
                onClick={processOCR}
                className="flex items-center gap-2 bg-blue-500 hover:bg-blue-600 text-white px-6 py-3 rounded-lg transition-colors"
              >
                <Search size={24} />
                Processar OCR
              </button>
            </div>
          </div>
        )}

        {isProcessing && (
          <div className="flex flex-col items-center gap-4 py-8">
            <Loader className="animate-spin" size={48} />
            <p className="text-lg text-gray-700 dark:text-white">Processando imagem...</p>
          </div>
        )}

        {ocrResult && (
          <div className="mt-6 p-4 bg-gray-100 dark:bg-gray-700 rounded-lg">
            <h3 className="text-lg font-semibold mb-2 text-gray-900 dark:text-white">
              Texto Extraído:
            </h3>
            <pre className="whitespace-pre-wrap font-mono text-sm text-gray-800 dark:text-gray-200">
              {ocrResult}
            </pre>
          </div>
        )}
      </div>
    </div>
  );
};