export async function saveWellnessTreatmentRequest( hotel, dataForm){
    const requestOptions = {
        method:"POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers:{"Content-Type":"application/json",
                 "Ocp-Apim-Subscription-Key": hotel.subscriptionKey,},
        body: JSON.stringify(dataForm)
    }
    const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/gxplocal/bridge/Gxp/Emenu/SaveWellnessTreatmentRequest`, requestOptions)

    if(!response.ok){
        throw {message: response.statusText, status: response.status}//eslint-disable-line
    }
    const data = await response.json()
    return data
}


export async function getEMenuHeaders(keys, cultureCode, hotel) {
    // Split the keys string by '-' and create the menuCodes URL parameters
    const menuCodes = keys
        .split('-')
        .map(key => `menuCodes=${key}`)
        .join('&');

    const requestOptions = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
            "Ocp-Apim-Subscription-Key": hotel.subscriptionKey,
        }
    };

    const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/gxplocal/bridge/Gxp/EMenu/Headers?${menuCodes}&cultureCode=${cultureCode}`, requestOptions );

    if (!response.ok) {
        throw { message: response.statusText, status: response.status }; //eslint-disable-line
    }

    const data = await response.json();
    console.log(data)
    return data;
}

export async function getEMenuDetails(key, cultureCode, hotel) {

    const requestOptions = {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
            "Ocp-Apim-Subscription-Key": hotel.subscriptionKey,
        }
    };

    const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/gxplocal/bridge/Gxp/EMenu/Details?data.headerId=${key}&data.menuId=-1&data.cultureCode=${cultureCode}&data.loadAllChildren=true`, requestOptions );

    if (!response.ok) {
        throw { message: response.statusText, status: response.status }; //eslint-disable-line
    }

    const data = await response.json();
    return data;
}