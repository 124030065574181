export const organizeMenuData = (menuDetails, allergens, messages, routeParams) => {
    // Função auxiliar para ordenar items por sortOrder
    const sortByOrder = (items) => {
      return items.sort((a, b) => a.SortOrder - b.SortOrder);
    };

    // Filtrar e ordenar os parents principais
    const mainParents = sortByOrder(menuDetails.filter(item => item.ParentId === 0));

    const processItem = (item) => {
      const processedItem = { ...item };

      // Processar allergens
      if (item.Allergens && item.Allergens.length > 0) {
        processedItem.AllergensData = item.Allergens.map(code =>
          allergens.find(a => a.Code === code)
        ).filter(Boolean);
      }

      // Processar messages
      if (item.MessageIds && item.MessageIds.length > 0) {
        processedItem.MessagesData = item.MessageIds.map(id =>
          messages.find(m => m.Id === id)
        ).filter(Boolean);
      }

      // Adicionar LinkTo para todos os items
      processedItem.LinkTo = `/hotel/${routeParams.platformUid}/${routeParams.detailId}/emenu/${routeParams.sUid}/${routeParams.key}/${item.Id}`;

      return processedItem;
    };

    const findChildren = (parentId) => {
      // Filtrar os filhos e ordená-los por sortOrder
      const children = sortByOrder(menuDetails.filter(item => item.ParentId === parentId));

      return children.map(child => {
        const processedChild = processItem(child);
        if (child.ItemId === 0) {
          processedChild.Children = findChildren(child.Id);
        }
        return processedChild;
      });
    };

    return mainParents.map(parent => {
      const processedParent = processItem(parent);
      processedParent.Children = findChildren(parent.Id);
      return processedParent;
    });
  };