import { useState, useMemo, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "antd";
import { ChevronDown } from "lucide-react";
import { AlertTriangle } from "lucide-react";

export const Title = ({ handleGuestChange, guest, initialData, requiredFields, error, cS, titles, militaryMode, titlesLoading, setTitlesLoading }) => {
  const [t] = useTranslation("global");
  const [isOpenArea, setIsOpenArea] = useState(false);
  const [isOpenRamo, setIsOpenRamo] = useState(false);
  const [isOpenPosto, setIsOpenPosto] = useState(false);
  const [selectedShortTitle, setSelectedShortTitle] = useState(null);
  const [selectedLongTitle, setSelectedLongTitle] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState(null);
  const dropdownRef = useRef(null);

  // Use o dropdownRender para customizar o menu e manter o scroll
  const handleDropdownRender = (menu) => (
    <div ref={dropdownRef}>{menu}</div>
  );

  // Organize validations for easier lookup
  const validationMap = useMemo(() => {
    if (!titles?.validations) return {};

    return titles.validations.reduce((acc, validation) => {
      if (!acc[validation.TitleShortId]) {
        acc[validation.TitleShortId] = {};
      }
      if (!acc[validation.TitleShortId][validation.TitleLongId]) {
        acc[validation.TitleShortId][validation.TitleLongId] = [];
      }
      acc[validation.TitleShortId][validation.TitleLongId].push(validation.TitleId);
      return acc;
    }, {});
  }, [titles?.validations]);

  // Get available long titles based on selected short title
  const availableLongTitles = useMemo(() => {
    if (!selectedShortTitle?.value || !validationMap[selectedShortTitle.value]) return [];

    const longTitleIds = Object.keys(validationMap[selectedShortTitle.value]);
    return titles?.longTitle?.filter(lt => longTitleIds.includes(lt.ID.toString())) || [];
  }, [selectedShortTitle, validationMap, titles?.longTitle]);

  // Get available titles based on selected short and long titles
  const availableTitles = useMemo(() => {
    if (!selectedShortTitle?.value || !selectedLongTitle?.value || !validationMap[selectedShortTitle.value]?.[selectedLongTitle.value]) {
      return [];
    }

    const titleIds = validationMap[selectedShortTitle.value][selectedLongTitle.value];
    return titles?.title?.filter(t => titleIds.includes(t.ID)) || [];
  }, [selectedShortTitle, selectedLongTitle, validationMap, titles?.title]);

// Handle short title change
const handleShortTitleChange = (selected) => {
  const value = selected?.value;
  const label = selected?.label;
  setSelectedShortTitle({ value, label });
  setSelectedLongTitle(null);
  setSelectedTitle(null);
  handleGuestChange(guest.id, "TitleShort_Id", value);
  handleGuestChange(guest.id, "TitleLong_Id", null);
  handleGuestChange(guest.id, "Title_Id", null);
};
// Handle long title change
const handleLongTitleChange = (selected) => {
  const value = selected?.value;
  const label = selected?.label;
  setSelectedLongTitle({ value, label });
  setSelectedTitle(null);
  handleGuestChange(guest.id, "TitleLong_Id", value);
  handleGuestChange(guest.id, "Title_Id", null);
};

// Handle title change
const handleTitleChange = (selected) => {
  const value = selected?.value;
  const label = selected?.label;
  setSelectedTitle({ value, label });
  handleGuestChange(guest.id, "Title_Id", value);
};

useEffect(() => {
  if (guest?.data) {
    const shortTitle = titles?.shortTitle?.find(st => st.ID === guest.data.TitleShort_Id);
    const longTitle = titles?.longTitle?.find(lt => lt.ID === guest.data.TitleLong_Id);
    const title = titles?.title?.find(t => t.ID === guest.data.Title_Id);

    if (shortTitle) {
      setSelectedShortTitle({ value: shortTitle.ID, label: shortTitle.Description });
    }
    if (longTitle) {
      setSelectedLongTitle({ value: longTitle.ID, label: longTitle.Description });
    }
    if (title) {
      setSelectedTitle({ value: title.ID, label: title.Description });
    }
  }
}, [guest?.data, titles]);

useEffect(() => {
  if (selectedTitle && dropdownRef.current) {
    const selectedOption = dropdownRef.current.querySelector(`.ant-select-item-option-selected`);
    if (selectedOption) {
      selectedOption.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }
}, [selectedTitle]);

  const isRequiredTitleId = requiredFields.some(
    (field) => field.Field === "Title_Id" && field.IsMandatory
  );

  const isRequiredShortId = requiredFields.some(
    (field) => field.Field === "TitleShort_Id" && field.IsMandatory
  );

  const isRequiredLongId = requiredFields.some(
    (field) => field.Field === "TitleLong_Id" && field.IsMandatory
  );

  // Check if the LastName field is required based on the provided validation rules
  const isRequired = requiredFields.some(
    (field) => field.Field === "Military_Id" && field.IsMandatory
  );

  return (
    <>
      {titles && guest && (
        <>
          <div className="flex flex-col">
            <label htmlFor="genero" className={`w-fit pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-left justify-center translate-x-3 bg-white dark:bg-gxp_dark_3 text-sm font-medium text-gray-700 dark:text-white mb-1 z-10 ${ parseInt(cS) ? "OC-bg" : `` }`} >
            {`${t(`Title.area`)}`}
              <span className="text-gray-400 dark:text-white ml-2"> {" "} {isRequiredShortId ? `( * )\u00A0` : ``}{" "} </span>
            </label>
            <Select
              suffixIcon={ <ChevronDown className={`text-gray-700 dark:text-white transition-transform duration-300 ease-in-out ${ isOpenArea ? "rotate-180" : "" }`} /> }
              onDropdownVisibleChange={(open) => setIsOpenArea(open)}
              className={`w-full py-2 text-xl rounded-lg border text-gray-700 ${ error.TitleShort_Id ? "border-red-500" : "border-gray-700 dark:border-white" } ${ parseInt(cS) ? "OC-fields-borders OC-bg" : `` } focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out appearance-none`}
              style={{ height: "45.5px" }}
              onChange={handleShortTitleChange}
              placeholder="Select an Area"
              popupClassName={`dark:dark`}
              showSearch
              dropdownStyle={{ minWidth: "260px" }}
              labelInValue
              value={selectedShortTitle}
              filterOption={(input, option) => option.children.props.children.props.children .toLowerCase() .includes(input.toLowerCase()) }
              virtual={false}
            >
              {titles.shortTitle?.map((title) => (
                <Select.Option key={title.ID} value={title.ID} className={`dark:dark`} >
                  <div className="flex items-center">
                    <span className="text-l">{title.Description}</span>
                  </div>
                </Select.Option>
              ))}
            </Select>
            {error.TitleShort_Id && ( <div className="flex mt-1 items-center"> <AlertTriangle className="text-red-500 mr-1" size={16} /> <span className="text-red-500 text-sm">{`${t( `HostForm.fieldRequired` )}`}</span> </div> )}
          </div>

          <div className="flex flex-col">
            <label htmlFor="genero" className={`w-fit pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-left justify-center translate-x-3 bg-white dark:bg-gxp_dark_3 text-sm font-medium text-gray-700 dark:text-white mb-1 z-10 ${ parseInt(cS) ? "OC-bg" : `` }`} >
            {`${t(`Title.branch`)}`} <span className="text-gray-400 dark:text-white ml-2"> {isRequiredLongId ? `( * )\u00A0` : ``} </span>
            </label>
            <Select suffixIcon={ <ChevronDown className={`text-gray-700 dark:text-white transition-transform duration-300 ease-in-out ${ isOpenRamo ? "rotate-180" : "" }`} /> }
              onDropdownVisibleChange={(open) => setIsOpenRamo(open)}
              className={`w-full py-2 text-xl rounded-lg border text-gray-700 ${ error.TitleLong_Id ? "border-red-500" : "border-gray-700 dark:border-white" } ${ parseInt(cS) ? "OC-fields-borders OC-bg" : `` } focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out appearance-none`}
              style={{ height: "45.5px" }}
              onChange={handleLongTitleChange}
              placeholder="Select o Ramo"
              popupClassName={`dark:dark`}
              showSearch
              dropdownStyle={{ minWidth: "260px" }}
              labelInValue
              value={selectedLongTitle}
              filterOption={(input, option) => option.children.props.children.props.children .toLowerCase() .includes(input.toLowerCase()) }
              disabled={!selectedShortTitle}
              virtual={false}
            >
              {availableLongTitles.map((title) => (
                <Select.Option key={title.ID} value={title.ID} className={`dark:dark`} >
                  <div className="flex items-center">
                    <span className="text-l">{title.Description}</span>
                  </div>
                </Select.Option>
              ))}
            </Select>
            {error.TitleLong_Id && ( <div className="flex mt-1 items-center"> <AlertTriangle className="text-red-500 mr-1" size={16} /> <span className="text-red-500 text-sm">{`${t( `HostForm.fieldRequired` )}`}</span> </div> )}
          </div>

          <div className="flex flex-col">
            <label htmlFor="genero" className={`w-fit pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-left justify-center translate-x-3 bg-white dark:bg-gxp_dark_3 text-sm font-medium text-gray-700 dark:text-white mb-1 z-10 ${ parseInt(cS) ? "OC-bg" : `` }`} >
            {`${t(`Title.post`)}`} <span className="text-gray-400 dark:text-white ml-2"> {isRequiredTitleId ? `( * )\u00A0` : ``} </span>
            </label>
            <Select suffixIcon={ <ChevronDown className={`text-gray-700 dark:text-white transition-transform duration-300 ease-in-out ${ isOpenPosto ? "rotate-180" : "" }`} /> }
              onDropdownVisibleChange={(open) => setIsOpenPosto(open)}
              className={`w-full py-2 text-xl rounded-lg border text-gray-700 ${ error.Title_Id ? "border-red-500" : "border-gray-700 dark:border-white" } ${ parseInt(cS) ? "OC-fields-borders OC-bg" : `` } focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out appearance-none`}
              style={{ height: "45.5px" }}
              onChange={handleTitleChange}
              placeholder="Select o Posto"
              popupClassName={`dark:dark`}
              showSearch
              dropdownStyle={{ minWidth: "260px" }}
              labelInValue
              dropdownRender={handleDropdownRender}  // Adicione o dropdownRender
              value={selectedTitle}
              filterOption={(input, option) => option.children.props.children.props.children .toLowerCase() .includes(input.toLowerCase()) }
              disabled={!selectedLongTitle}
              virtual={false}
            >
              {availableTitles.map((title) => (
                <Select.Option key={title.ID} value={title.ID} className={`dark:dark`}>
                  <div className="flex items-center">
                    <span className="text-l">{title.Description}</span>
                  </div>
                </Select.Option>
              ))}
            </Select>
            {error.Title_Id && ( <div className="flex mt-1 items-center"> <AlertTriangle className="text-red-500 mr-1" size={16} /> <span className="text-red-500 text-sm">{`${t( `HostForm.fieldRequired` )}`}</span> </div> )}
          </div>

          <div>
            <label htmlFor="apelido" required={isRequired} className={`pl-2 inline-block rounded-xl translate-y-3.5 text-center justify-center translate-x-3 bg-white dark:bg-gxp_dark_3 text-sm font-medium text-gray-700 dark:text-white mb-1 ${ parseInt(cS) ? "OC-bg" : `` }`} >
              Military Id <span className="text-gray-400 dark:text-white ml-2"> {isRequired ? `( * )\u00A0` : ``} </span>
            </label>
            <input name="Military_Id" onChange={(e) => handleGuestChange(guest.id, e.target.name, e.target.value) } value={guest.data.Military_Id || ""} type="text" id="apelido" className={`w-full px-4 py-2 text-xl rounded-lg border dark:bg-gxp_dark_3 dark:text-white ${ error.Military_Id ? "border-red-500" : "border-gray-700 dark:border-white" } ${ parseInt(cS) ? "OC-fields-borders OC-bg" : `` } focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out`} />
            {error.Military_Id && ( <div className="flex mt-1 items-center"> <AlertTriangle className="text-red-500 mr-1" size={16} /> <span className="text-red-500 text-sm">{`${t( `HostForm.fieldRequired` )}`}</span> </div> )}
          </div>
        </>
      )}
    </>
  );
};
