import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { toast } from "react-toastify";
import { getWebText } from "../../services";
import { useCookies } from "react-cookie";

/**
 * @module WebText
 * @description Fetches and displays dynamic web content based on the provided `platformUid` and `key` from the URL.
 * The content is fetched from a backend service and displayed in HTML format.
 * This component is designed for showing textual content, such as terms, privacy policies, or any other web-based text that needs to be displayed dynamically.
 *
 * @author Tiago Ferreira
 * @version 1.0.0
 * @example
 * <WebText />
 */
export const WebText = () => {
  const [state, dispatch] = useContext(AppContext);   // Retrieves global state from the context, including dark mode settings
  const { platformUid, key } = useParams();   // Retrieves dynamic URL parameters: platformUid and key, which are used to fetch web content
  const [principalHotel, setPrincipalHotel] = useState();   // Local states to manage the fetched content and hotel data
  const [webText, setWebText] = useState();
  // Retrieves cookies, particularly the current hotel and platformUid, used to fetch web text from the service
  const [cookie, setCookie] = useCookies(["platformUid", "currentHotel"]); // eslint-disable-line

    /**
   * @function fetchWebText
   * @description Fetches the web text based on the `key` and the hotel-specific data stored in cookies.
   * The `webText` is then set in the component's state to be rendered in HTML format.
   * Displays an error toast if the data fetching fails.
   *
   * @async
   * @returns {void}
   * @author Tiago Ferreira
   * @version 1.0.0
   * @example
   * useEffect(() => {
   *   fetchWebText();
   * }, [key, principalHotel]);
   */
  useEffect(() => {
    if (cookie.currentHotel.subscriptionKey) {
      getWebText(JSON.parse(localStorage.getItem('userLanguage')), key, cookie.currentHotel.subscriptionKey )
        .then((data) => {
          setWebText(data[0]?.TextPlain);
        })
        .catch((error) => {
          console.error("Error fetching hotel info:", error);
          toast.error("Unable to get hotel info!");
        });
    }
  }, [key, principalHotel, JSON.parse(localStorage.getItem('userLanguage'))]); // eslint-disable-line

  return (
    <div className={`${ state.darkMode ? "" : "" } antialiased dark:text-white h-full`} >
      <section className="flex-grow p-4">
        <div className={`text-justify`} dangerouslySetInnerHTML={{ __html: webText }} >
        </div>
      </section>
    </div>
  );
};
