import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getPriceByLevel } from './getPriceByLevel';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { NotebookPen } from 'lucide-react';
import { WellnessFormModal } from './WellnessFormModal';

export const MenuItem = ({ item, service }) => {
  const [stableItem, setStableItem] = useState(null);
  const [isStable, setIsStable] = useState(false);
  const [t] = useTranslation("global");
  const MotionLink = motion.create(Link);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const PriceDisplay = ({ originalPrice, discountPrice }) => (

    <div className="flex flex-col items-end">
      <div className="flex items-center gap-2">
        <div className="bg-red-500 text-white text-sm px-2 py-0.5 rounded-full animate-pulse">
        {`${t("MenuItem.promotion")}`}
        </div>
        <div className="text-lg font-bold text-red-500 whitespace-nowrap">
          {discountPrice}€
        </div>
      </div>
      <div className="text-sm text-gray-500 dark:text-gray-400 line-through">
        {originalPrice}€
      </div>
    </div>
  );

  useEffect(() => {
    if (!item) return;
    const timer = setTimeout(() => {
      setStableItem(item);
      setIsStable(true);
    }, 100);

    return () => clearTimeout(timer);
  }, [item, item.hh, item.AllPrices]);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    // Cleanup ao desmontar
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isModalOpen]);

  if (!isStable || !stableItem) {
    return <div className="animate-pulse bg-gray-200 dark:bg-gray-700 h-24 rounded-lg"></div>;
  }

  if (stableItem.ParentId === 0 || stableItem.ItemId === 0) {
    return (
      stableItem.ImageUrl ?
      <MotionLink key={stableItem.Id} to={stableItem.LinkTo} whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.9 }} className="block">
        <div className="relative h-48 rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow">
          <img src={stableItem.ImageUrl || '/api/placeholder/400/320'} alt={stableItem.Description} className="w-full h-full object-cover" />
          <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
          <div className="absolute bottom-0 left-0 right-0 p-4">
            <h2 className="text-white text-xl font-semibold">
              {stableItem.Description}
            </h2>
            {stableItem.Notes && (
              <p className="text-white/80 text-sm mt-1">{stableItem.Notes}</p>
            )}
          </div>
        </div>
      </MotionLink>
      :
      <MotionLink to={stableItem.LinkTo} whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.9 }} className="block">
        <div className="relative h-48 w-full overflow-hidden bg-white border-violet-100 dark:bg-gxp_dark_3 dark:border-gray-800 text-gray-600 dark:text-white border-2 rounded-lg shadow-md p-4 mb-4 hover:shadow-lg transition-shadow duration-300">
          <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
          <div className="absolute bottom-0 left-0 right-0 p-4">
            <h2 className="text-white text-xl font-semibold">
              {stableItem.Description}
            </h2>
          </div>
        </div>
      </MotionLink>
    );
  }

  const originalPrice = stableItem.AllPrices ?
    getPriceByLevel(stableItem.AllPrices, stableItem.PriceLevel)?.toFixed(2) : null;

  return (
    <div className="bg-white dark:bg-gxp_dark_3 rounded-lg p-2 shadow-sm sm:flex group">
      {stableItem.ImageUrl && (
        <div className='max-sm:flex max-sm:justify-center max-sm:items-center max-sm:mb-4'>
          <img src={stableItem.ImageUrl || '/api/placeholder/400/320'} alt={stableItem.Description} className="w-1/2 sm:w-40 h-auto sm:mr-4" />
        </div>
      )}
      <div className="flex flex-col justify-between items-start gap-2 flex-1 w-full">
        <div className="flex justify-between flex-1 w-full">
          <h4 className="text-lg font-medium text-gray-900 dark:text-white">
            {stableItem.Description}
          </h4>
          <div className='flex items-start'>
            {stableItem.hh && originalPrice ? (
              <PriceDisplay originalPrice={originalPrice} discountPrice={stableItem.hh} />
            ) : (
              stableItem.AllPrices && (
                <div className="text-lg font-semibold text-gray-900 dark:text-white whitespace-nowrap">
                  {originalPrice}€
                </div>
              )
            )}
          </div>
        </div>
        {stableItem.Notes && (
          <div className='flex justify-between items-end gap-2 w-full'>
            <span className="text-sm text-gray-600 dark:text-white whitespace-pre-line">
              {stableItem.Notes}
            </span>
            {stableItem.Allergens?.length === 0 && service.AllowWellnessReservation && stableItem.IsSpa && stableItem.ItemType===4 && (
              <button type='button' className='flex justify-end items-center' onClick={() => setIsModalOpen(true)}>
                <NotebookPen height={35} width={35} className='bg-gray-100 dark:bg-gray-100/60 p-1 rounded-lg' />
              </button>
            )}
          </div>
        )}
        {stableItem.AllergensData?.length > 0 && (
          <div className="flex flex-col gap-2 mt-2 w-full">
            {stableItem.AllergensData.map((allergen) => (
              <div key={allergen.Code} className='flex justify-between items-end gap-2 w-full'>
                <div className='flex justify-center items-center'>
                  <img src={allergen.ImageUrl || '/api/placeholder/400/320'} alt={allergen.Description} className="w-4 h-4" />
                  <span className="text-xs bg-gray-100 dark:bg-gray-100/60 px-2 py-1 rounded-lg ml-2">
                    {allergen.Description}
                  </span>
                </div>
              </div>
            ))}
            {service.AllowWellnessReservation && stableItem.IsSpa && stableItem.ItemType===4 && (
              <button type='button' className='flex justify-end items-center' onClick={() => setIsModalOpen(true)}>
                <NotebookPen height={35} width={35} className='bg-gray-100 dark:bg-gray-100/60 p-1 rounded-lg' />
              </button>
            )}
          </div>
        )}
      </div>
      <WellnessFormModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} item={stableItem} service={service} />
    </div>
  );
};