import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

/**
 * @module PageNotFound
 * @description Displays a 404 error page when the user navigates to a non-existent route.
 * It includes dynamic content based on the current theme and offers navigation options to go back to the previous page.
 *
 * @author Tiago Ferreira
 * @version 1.0.0
 * @example
 * <PageNotFound />
 */
export const PageNotFound = () => {
  const [state] = useContext(AppContext); // Retrieves the application state (e.g., dark mode setting)
  const [cookie, setCookie] = useCookies(['initialLink']); // Manages cookies, specifically `initialLink`
  const [t] = useTranslation("global"); // Fetches translation functions for text localization
  const navigate = useNavigate(); // Provides navigation capabilities for going back to the previous page

  return (
    <div className={`${ state.darkMode ? "" : "" } antialiased dark:text-white h-full`} >
      <section className="flex-grow p-4">
        <div className="mx-auto max-w-screen-sm text-center px-10">
          <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-gray-700 dark:text-blue-600"> 404 </h1>
          <p className="mb-4 text-3xl tracking-tight font-bold text-gray-600 md:text-4xl dark:text-white">{`${t(`PageNotFound.missing`)}`}</p>
          <p className="mb-4 text-lg font-light text-gray-600 dark:text-gray-400">{`${t(`PageNotFound.sorry`)}`}</p>
          <button onClick={()=>navigate(-1)} className="inline-flex text-gray-600 dark:text-white bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4" >
          {`${t(`PageNotFound.back`)}`}
          </button>
        </div>
      </section>
    </div>
  );
};
