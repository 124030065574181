import { useState, useContext, useEffect } from "react";
import { HostForm } from "./HostForm";
import { AppContext } from "../../../context/AppContext";
import { getHotelInitialData, getHotelTitles, saveReservationGuests } from "../../../services";
import { toast } from "react-toastify";
import { Consent } from "./Consent";
import { GeneralTerms } from "./GeneralTerms";
import { ArrivalTime } from "./ArrivalTime";
import { SquarePen } from "lucide-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Stepper from "./ProgressBar/Stepper";
import { HeadersPage } from "../../../components/Layout/HeadersPage";
import { Loader } from "../../../components/Layout/components/Loader/Loader";
import { LanguageSelectorOC } from "./LanguageSelectorOC";

/**
 * @module HostFormComplete
 * @description Renders the host form for guests' online check-in, allowing adding and removing guests, and submitting their information.
 * @param {Object} props - Component props.
 * @param {Object} props.hotel - Hotel data.
 * @param {Object} props.reservation - Reservation data.
 * @param {function} props.setReservation - Function to update the reservation state.
 * @returns {JSX.Element} Rendered component.
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 * @example
 * <HostFormComplete hotel={hotel} reservation={reservation} setReservation={setReservation} />
 */
export const HostFormComplete = ({ hotel, reservation, setReservation, cS, showMenus, cultureCode }) => {
  const [guests, setGuests] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [state,dispatch] = useContext(AppContext);
  const [initialData, setInitialData] = useState(state.hotelInitialData);
  const [showConsent, setShowConsent] = useState(false);
  const [showGeneralTerms, setShowGeneralTerms] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [successSubmit, setSuccessSubmit] = useState(false);
  const [t] = useTranslation("global");
  const [step, setStep] = useState(1);
  const [totalSteps, setTotalSteps] = useState(3);
  const [militaryMode, setMilitaryMode]= useState(false)
  const [titles, setTitles]= useState({})
  const [titlesLoading, setTitlesLoading] = useState(true);
  const [post, setPost] = useState("")
  const [userLanguage, setUserLanguage]=useState(localStorage.getItem('userLanguage'))
  const navigate = useNavigate()
  const location = useLocation()
  const [isSending, setIsSending]=useState(false)
  const { pathname } = useLocation();

  const isDirect = pathname.includes("/checkindirect");

  useEffect(()=>{
    const guest = guests[0]
    if(militaryMode && titles && guest){
      const newTitle = titles?.result?.title.find((element) => element.ID === guest.data.Title_Id)
      setPost(newTitle)
    }
  },[guests, titles, militaryMode])

  /**
   * @function addNewGuest
   * @description Adds a new guest to the guests list and sets the active tab to the new guest.
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * addNewGuest();
   */
  const addNewGuest = () => {
    const newGuest = {
      id: guests.length,
      title: `${t(`HostFormComplete.guest`)} ${guests.length + 1}`,
      data: {},
    };
    setGuests([...guests, newGuest]);
    setActiveTab(newGuest.id);
  };

  /**
   * @function removeGuest
   * @description Removes a guest from the guests list based on the provided ID.
   * @param {number} id - The ID of the guest to remove.
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * removeGuest(1);
   */
  const removeGuest = (id) => {
    if (id === 0) return; // Do not allow removal of the main guest
    const newGuests = guests.filter((guest) => guest.id !== id);
    setGuests(newGuests);
    setActiveTab(newGuests[newGuests.length - 1].id);
  };

  /**
   * @function extractData
   * @description Extracts guest data from the guests list.
   * @param {Array} guests - List of guest objects.
   * @returns {Array} Array of guest data objects.
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * const data = extractData(guests);
   */
  function extractData(guests) {
    return guests.map((guest) => guest.data);
  }

  /**
   * @function handleSubmit
   * @description Handles form submission, checking for required data and saving the reservation guests.
   * @param {Event} e - The form submit event.
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * handleSubmit(event);
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!guests[0].data.GeneralTermsAccepted) {
      alert(`${t(`HostFormComplete.acceptgeneral`)}`);
      return;
    }
    if (!guests[0].data.ArrivalTime) {
      alert(`${t(`HostFormComplete.arrivaltime`)}`);
      return;
    }
    if (canSubmit) {
      try {
        setIsSending(true);
        const data = await saveReservationGuests(hotel, extractData(guests));
        setSuccessSubmit(true);
      } catch (error) {
        alert(`${t('HostFormComplete.formfail')}`, error);
      } finally {
        setIsSending(false);
      }
    } else {
      alert(`${t(`HostFormComplete.arrivaltime`)}`);
    }
  };

  /**
   * @function next
   * @description Handles the logic for moving to the next guest or showing the consent or general terms.
   * @param {Event} e - The event triggered by the button click.
   * @param {number} id - The current guest index.
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * next(event, guestId);
   */
  const next = (e, id) => {
    e.preventDefault();
    if (!showGeneralTerms && showConsent && id === guests.length - 1) {
      setShowGeneralTerms(true);
      setShowConsent(false);
      setStep(step + 1);
      window.scrollTo(0, 0);
      return;
    }
    if (!showGeneralTerms && !showConsent && id === guests.length - 1) {
      setShowConsent(true);
      setActiveTab(0);
      setStep(step + 1);
      window.scrollTo(0, 0);
      return;
    }
    setActiveTab(id + 1);
    window.scrollTo(0, 0);
  };

  /**
   * @function previous
   * @description Handles the logic for moving to the previous guest or showing the consent or general terms.
   * @param {Event} e - The event triggered by the button click.
   * @param {number} id - The current guest index.
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * previous(event, guestId);
   */
  const previous = (e, id) => {
    e.preventDefault();
    if (showGeneralTerms && !showConsent && id === 0) {
      setShowConsent(true);
      setShowGeneralTerms(false);
      setActiveTab(guests.length - 1);
      setStep(step - 1);
      window.scrollTo(0, 0);
      return;
    }
    if (!showGeneralTerms && showConsent && id === 0) {
      setShowConsent(false);
      setActiveTab(guests.length - 1);
      setStep(step - 1);
      window.scrollTo(0, 0);
      return;
    }
    setActiveTab(id - 1);
    window.scrollTo(0, 0);
  };

  const fetchHostTitles = async () =>{
    try {
      const result = await getHotelTitles(cultureCode, hotel.subscriptionKey)
      setTitles(result)
    } catch (error) {
      console.error("Error loading host titles:", error);
      toast.error(t("Error loading host titles"));
    } finally {
      setTitlesLoading(false)
    }

  }

  /**
   * @events useEffectSetGuests
   * @description Sets the guests state based on the reservation data.
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * useEffect(() => {
   *   // Logic here
   * }, [reservation]);
   */
  useEffect(() => {
    if (reservation) {
      if (Array.isArray(reservation.Guests)) {
        // Store the number of adults
        const numberOfAdults = reservation.Adults || 0;
        const numberOfGuests = reservation.Guests.length;

        // If the number of guests equals the number of adults, keep it as is
        if (numberOfGuests === numberOfAdults) {
          setGuests(
            reservation.Guests.map((guestData, index) => ({
              id: index,
              title:
                index === 0
                  ? `${t(`HostFormComplete.mainguest`)}`
                  : `${t(`HostFormComplete.guest`)} ${index + 1}`,
              data: guestData,
            }))
          );
        } else {
          // If the number of guests is less than the number of adults, copy the first guest
          const mainGuest = reservation.Guests[0];

          const newGuests = reservation.Guests.map((guestData, index) => ({
            id: index,
            title:
              index === 0
                ? `${t(`HostFormComplete.mainguest`)}`
                : `${t(`HostFormComplete.guest`)} ${index + 1}`,
            data: guestData,
          }));

          // Add empty guests until the number of guests equals the number of adults
          for (let i = numberOfGuests; i < numberOfAdults; i++) {
            newGuests.push({
              id: i,
              title: `${t(`HostFormComplete.guest`)} ${i + 1}`,
              data: {
                ...mainGuest,
                Address1: "",
                Address2: "",
                Address3: "",
                AddressState: "",
                BirthDate: "",
                CountryId: null,
                CountryIso: "",
                DocIssueDate: null,
                DocNumber: "",
                DocType: null,
                DocVal: "",
                Email: "",
                FirstName: "",
                FiscalNumber: "",
                Gender: "",
                GuestAgeAtCheckInDate: 0,
                GuestId: -1,
                GuestSignatureBase64: "",
                GuestUid: "",
                isMainGuest: false,
                LastName: "",
                Military_Id: "",
                Mobile: "",
                NationalityId: null,
                NationalityIso: "",
                Phone: "",
                RegionCode: null,
                RegionId: 0,
                ResDetailId: 285628,
                ReservationNotes: "",
                SocialName: "",
                Title_Id: null,
                TitleLong_Id: null,
                TitleShort_Id: null,
                Town: "",
                ZipCode: "",
              },
            });
          }
          setGuests(newGuests);
        }
      }
    }
  }, [reservation]);


  /**
   * @events useEffectUpdateReservation
   * @description Updates the reservation state with the guests' data when the guests change.
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * useEffect(() => {
   *   // Logic here
   * }, []);
   */
  useEffect(() => {
    setReservation((prevReservation) => ({
      ...prevReservation,
      Guests: guests.map((guest) => guest.data), // Updating only with guest data
    }));
  }, []); // Triggered when `guests` changes

  /**
   * @events useEffectFetchHotel
   * @description Fetches initial hotel data when the hotel prop changes.
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * useEffect(() => {
   *   // Logic here
   * }, [hotel]);
   */
  useEffect(() => {
    const fetchHotelData = async () => {
      if (!hotel) return;
      if (state.hotelInitialData) return;

      try {
        const data = await getHotelInitialData(hotel, userLanguage );
        dispatch({ type: "SET_HOTEL_INITIALDATA", payload: data.InitialData });

        setInitialData(data.InitialData);
        setMilitaryMode(data.InitialData.Parameters.MilitaryMode);
      } catch (error) {
        console.error(`${t(`OnlineCheckIn.errorhotel`)}`, error);
        toast.error(`${t(`OnlineCheckIn.errorhotel`)}`);
      }
    };
    fetchHotelData();
  }, [hotel]); // eslint-disable-line

  useEffect(() => {
    if (militaryMode) {
      fetchHostTitles();
    }
  }, [initialData]);

  useEffect(() => {
    setTotalSteps(guests.length * 2 + 1);
  }, [guests]);

  if (successSubmit) {
    // Success component
    return (
      <div className="text-center">
        <div className={` px-8 py-6 rounded-xl mx-auto`} >
          <h2 className={` text-3xl font-bold text-gray-800 dark:text-white mb-4`} >{`${t(`HostFormComplete.checkin`)}`}</h2>
          <div className="flex justify-between items-center">
            {" "}
            <h2 className="text-xl font-bold mb-4 dark:text-white">{`${t( `HostFormComplete.success` )}`}</h2>{" "}
            <SquarePen onClick={() => setSuccessSubmit(false)} className="cursor-pointer dark:text-white" />{" "}
          </div>

          <div className="bg-green-100 text-green-700 p-4 rounded-lg mb-4 flex justify-between">
            { militaryMode && post?
              <p> {`${t(`HostFormComplete.thankyou`)}`} {post.Description} {guests[0].data?.LastName || `${t(`HostFormComplete.guest`)}`} {`${t(`HostFormComplete.formsuccess`)}`} </p>
            :
              <p> {`${t(`HostFormComplete.thankyou`)}`} {guests[0].data?.FirstName || `${t(`HostFormComplete.guest`)}`} {`${t(`HostFormComplete.formsuccess`)}`} </p>
            }
            { location.pathname.includes(`/checkindirect`)?
              <button onClick={() => navigate(-1)} className="">
              <b>{`${t(`HostFormComplete.backpage`)}`}</b>{" "}
              </button>
            :
              <Link to={`/hotel/${hotel.pmsId.substring(0, 8)}/${reservation.DetailId}`}>
              <b>{`${t(`HostFormComplete.backhotel`)}`}</b>{" "}
              </Link>
            }
          </div>
        </div>
      </div>
    );
  }

  // style={{ backgroundImage: window.innerWidth >= 640 ? `url(${onlineCheckInDesktop})` : `url(${onlineCheckIn})`, backgroundSize: 'auto'}}


  return (
    <>
        <div className="relative"> {/* Container relativo para posicionamento absoluto */}
          {/* Language Selector posicionado absolutamente */}
          { isDirect &&
            <div className="absolute top-4 right-4 z-50">
              <LanguageSelectorOC />
            </div>
          }
        <form id="onlineCheckinForm" onSubmit={handleSubmit} className={`mx-auto`} >
          <HeadersPage title={`${t(`HostFormComplete.checkin`)}`} room={reservation?.room} militaryMode={militaryMode} guest={guests[0]} post={post} />
          <div className="flex flex-col justify-center">
            <Stepper step={step} totalSteps={totalSteps} />
          </div>
          {reservation && hotel && guests.length > 0 ? (
            <>
          {guests.map((guest, index) => (
            <div key={guest.id} style={{ display: activeTab === guest.id ? "block" : "none" }} className={`md:px-10  ${showMenus ? "" : ""}`} >
              {!showConsent && !showGeneralTerms && (
                <HostForm titlesLoading={titlesLoading} setTitlesLoading={setTitlesLoading} cS={cS} activeTab={activeTab} showGeneralTerms={showGeneralTerms} showConsent={showConsent} previous={previous} next={next} isMain={index === 0} mainGuest={guests[0]} guest={guest} guests={guests} setGuests={setGuests} initialData={initialData} titles={titles.result} militaryMode={militaryMode} />
              )}
              {showConsent && !showGeneralTerms && (
                <Consent activeTab={activeTab} showGeneralTerms={showGeneralTerms} showConsent={showConsent} previous={previous} next={next} guest={guest} guests={guests} setGuests={setGuests} titles={titles} militaryMode={militaryMode} />
              )}
              {showGeneralTerms && !showConsent && (
                <GeneralTerms setCanSubmit={setCanSubmit} activeTab={activeTab} showGeneralTerms={showGeneralTerms} showConsent={showConsent} previous={previous} next={next} guests={guests} setGuests={setGuests} initialData={initialData} />
              )}
            </div>
          ))}
          </>
        ) : (<div className="w-full flex flex-col items-center"> <Loader className={`mt-20 h-20 w-20 dark:text-white`} /> </div> )}
          <ArrivalTime guests={guests} setGuests={setGuests} />
          {guests.length > 0 && reservation && showGeneralTerms && (
            <div className="flex justify-center">
              {isSending ? (
                <button type="button" className={`w-auto px-6 py-3 mt-5 mb-6  ${ canSubmit ? "bg-blue-600" : "bg-gray-400 cursor-not-allowed" } bg-blue-600 text-white font-medium rounded-lg shadow-md transition duration-150 ease-in-out`} >
                  <Loader />
                </button>
              ) : (
              <button onSubmit={(e) => handleSubmit(e)} className={`w-auto px-6 py-3 mt-5 mb-6  ${ canSubmit ? "bg-blue-600" : "bg-gray-400 cursor-not-allowed" } bg-blue-600 text-white font-medium rounded-lg shadow-md transition duration-150 ease-in-out`} >
                {`${t(`HostFormComplete.submit`)}`}
              </button>)}
            </div>
          )}
        </form>
        </div>
    </>
  );
};
