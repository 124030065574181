import dayjs from "dayjs";
import { BedDouble, Calendar, ChevronRight, Package, Users, } from "lucide-react";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { getHotelInitialData } from "../../../services";
import { Loader } from "../../../components/Layout/components/Loader/Loader";

export const ReservationCard = ({ reservations, isLoading, setIsLoading }) => {
  const [t] = useTranslation("global");
  const [state, dispatch] = useContext(AppContext);
  const [hotelImage, setHotelImage] = useState();
  const [hotelInitialData, setHotelInitialData] = useState();
  const [isLoadingHotelInitialData, setIsLoadingHotelInitialData] = useState(true);

  const fetchedRef = useRef(false);

  useEffect(() => {
    const fetchHotelInitialData = async () => {
      // Evita refetch se já foi carregado
      if (reservations && !fetchedRef.current) {
        try {
          setIsLoadingHotelInitialData(true);
          // Mantém referência de que já foi feito o fetch
          fetchedRef.current = true;

          const hotelInitialData = await getHotelInitialData(
            reservations.hotel,
            localStorage.getItem('userLanguage')
          );
          setHotelInitialData(hotelInitialData.InitialData);
        } catch (error) {
          console.error("Error loading initialData-AllRoutes:", error);
        } finally {
          setIsLoadingHotelInitialData(false);
        }
      }
    };

    fetchHotelInitialData();
  }, [reservations]);

  useMemo(() => {
    if (hotelInitialData?.Hotel.HotelImages.length > 0) {
      const newImage = hotelInitialData.Hotel.HotelImages.find(image => image.Code === "GXP_Hotel_reservation_cover")
      setHotelImage(newImage)
    }
  }, [hotelInitialData])

  return (
    <>
      {isLoadingHotelInitialData &&
      <div className="h-full w-full flex flex-coll justify-center items-center">
        <Loader className={`mt-20 h-20 w-20 dark:text-white`} />
      </div>
      }

      {!isLoadingHotelInitialData && reservations.reservations.map((reservation, index) => (
        !hotelImage ? (
        <Link to={`/hotel/${reservations.hotel.hotelId.substring(0,8)}/${reservation.detailid}`} key={index} className=" flex flex-col bg-white border-violet-100 dark:bg-gxp_dark_3 dark:border-gray-800 text-gray-600 dark:text-white border-2 rounded-lg shadow-md p-4 mb-4 hover:shadow-lg transition-shadow duration-300" >
          <div className="flex justify-between items-center mb-2">
            <span className="text-md font-semibold"> {`${t("reservation")}`} {reservation.resno} </span>
            <div className="flex justify-center items-center space-x-3">
              <div className="hover:text-gxp dark:text-white transition-colors duration-200" aria-label="Online Check-in" >
                <ChevronRight className="w-5 h-5" />
              </div>
            </div>
          </div>
          <div className="flex items-center mb-2">
            <Calendar className="w-5 h-5 mr-2 text-gxp_violet dark:text-violet-400" />
            <span className="text-sm">
              {dayjs(reservation.checkin).format(t("dayjs_format"))} - {" "}
              {dayjs(reservation.checkout).format(t("dayjs_format"))}
            </span>
          </div>
          <div className="flex items-center mb-2">
            <BedDouble className="w-5 h-5 mr-2 text-gxp_violet dark:text-violet-400" />
            <p className="text-sm">
            {`${t("Checkout.room")}`} {reservation.room} - {reservation.categoryDescription}
            </p>
          </div>
          <div className="flex items-center mb-2">
            <Users className="w-5 h-5 mr-2 text-gxp_violet dark:text-violet-400" />
            <p className="text-sm">{reservation.pax + reservation.children + reservation.children2 + reservation.children3} {`${t("HotelReservationDetails.guests")}`} ({reservation.pax} {`${t("HotelReservationDetails.adults")}`},{" "} {reservation.children + reservation.children2 + reservation.children3}{" "} {`${t("HotelReservationDetails.children")}`}){" "} </p>
          </div>
          <div className="flex items-center">
            <Package className="w-5 h-5 mr-2 text-gxp_violet dark:text-gxp_violet_dark" />
            <p className="text-sm">{reservation.packDescription}</p>
            </div>
          </Link>
        ) : (
          <Link to={`/hotel/${reservations.hotel.hotelId.substring(0,8)}/${reservation.detailid}`} key={index} className="relative flex flex-col bg-white border-violet-100 dark:bg-gxp_dark_3 dark:border-gray-800 text-gray-600 dark:text-white border-2 rounded-lg shadow-md p-4 mb-4 hover:shadow-lg transition-shadow duration-300" style={{ backgroundImage: `url(${hotelImage?.Url})`, backgroundSize: 'cover', backgroundPosition: 'center' }} >
            {/* Semi-transparent overlay */}
            <div className="absolute inset-0 bg-black/60 rounded-md" />
            {/* Content container with higher z-index */}
            <div className="relative text-white">
              <div className="flex justify-between items-center mb-2">
                <span className="text-md font-semibold">
                  {`${t("reservation")}`} {reservation.resno}
                </span>
                <div className="flex justify-center items-center space-x-3">
                  <div className="hover:text-gxp dark:text-white transition-colors duration-200" aria-label="Online Check-in">
                    <ChevronRight className="w-5 h-5" />
                  </div>
                </div>
              </div>

              <div className="flex items-center mb-2">
                <Calendar className="w-5 h-5 mr-2 text-gxp_violet dark:text-violet-400" />
                <span className="text-sm">
                  {dayjs(reservation.checkin).format(t("dayjs_format"))} - {" "}
                  {dayjs(reservation.checkout).format(t("dayjs_format"))}
                </span>
              </div>

              <div className="flex items-center mb-2">
                <BedDouble className="w-5 h-5 mr-2 text-gxp_violet dark:text-violet-400" />
                <p className="text-sm">
                  {`${t("Checkout.room")}`} {reservation.room} - {reservation.categoryDescription}
                </p>
              </div>

              <div className="flex items-center mb-2">
                <Users className="w-5 h-5 mr-2 text-gxp_violet dark:text-violet-400" />
                <p className="text-sm">
                  {reservation.pax + reservation.children + reservation.children2 + reservation.children3} {`${t("HotelReservationDetails.guests")}`}
                  ({reservation.pax} {`${t("HotelReservationDetails.adults")}`}, {reservation.children + reservation.children2 + reservation.children3} {`${t("HotelReservationDetails.children")}`})
                </p>
              </div>

              <div className="flex items-center">
                <Package className="w-5 h-5 mr-2 text-gxp_violet dark:text-violet-400" />
                <p className="text-sm">{reservation.packDescription}</p>
              </div>
            </div>
          </Link>
        )
      ))}
    </>
  );
};
