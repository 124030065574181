import { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { HostFormComplete } from "./components/HostFormComplete";
import { toast } from "react-toastify";
import { getHostProfile, getHotelPmsIdPrefix, searchReservation } from "../../services";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";
import { AppContext } from "../../context/AppContext";
import { updateI18nLanguage } from "../Settings/Language/ConfigureI18n";

export const OnlineCheckIn = () => {
  const { platformUid, detailId, resNo, sUid, cS } = useParams();
  const [state,dispatch] = useContext(AppContext);
  const [cookies, setCookie] = useCookies([ "cultureCode", "GUid", "currentHotel", "sUid", "profileKey", "guest", "isProfileLoaded", ]);
  const [data, setData] = useState({ groupHotels: [], isLoading: true, isInitialized: false, profileLoaded: false, reservationLoaded: false });
  const [reservation, setReservation] = useState(state.reservation)
  const [t] = useTranslation("global");
  const { pathname } = useLocation();
  const showMenus = !pathname.includes("/checkindirect");
  const [guestLanguage, setGuestLanguage]= useState(localStorage.getItem(`userLanguage`))

  const loadHotels = async () => {
    if (!platformUid || !pathname.includes("/checkindirect")) return;

    try {
      const hotelsData = await getHotelPmsIdPrefix(platformUid);
      setData(prev => ({ ...prev, groupHotels: hotelsData }));

      // Encontra e define o hotel principal
      for (const hotel of hotelsData) {
        const pmsIdPrefix = hotel.pmsId.slice(0, 8);
        if (pmsIdPrefix === platformUid) {
          setCookie("currentHotel", hotel, { path: "/" });
          break;
        }
      }

      // Define sUid nos cookies se existir
      if (sUid) {
        setCookie("sUid", sUid, { path: "/" });
      }
    } catch (error) {
      console.error("Error loading hotels:", error);
      toast.error("Unable to get hotels!");
    }
  };

  const loadProfile = async () => {
    if (!cookies.currentHotel?.subscriptionKey || !sUid || data.profileLoaded) return;

    try {
      const profileResult = await getHostProfile( sUid, cookies.profileKey, cookies.currentHotel.subscriptionKey );

      setCookie("profileKey", profileResult.ProfileKey, { path: "/" });
      setCookie("GUid", profileResult.Uid, { path: "/" });
      setCookie("guest", JSON.stringify(profileResult), { path: "/" });
      setCookie("isProfileLoaded", true, { path: "/" });

      setData(prev => ({ ...prev, profileLoaded: true }));
    } catch (error) {
      console.error("Error loading profile:", error);
      toast.error(t("OnlineCheckIn.errorhost"));
    }
  };

  const loadReservation = async () => {
    if (!cookies.currentHotel || !cookies.GUid || !detailId || !resNo || data.reservationLoaded) return;

    try {
        const reservationResponse = await searchReservation( resNo, detailId, cookies.currentHotel, cookies.GUid, cookies.currentHotel.defaultCultureCode );
        setReservation(reservationResponse.Data?.Details[0])
        setData(prev => ({
          ...prev,
          reservationLoaded: true,
          isLoading: false
        }));
    } catch (error) {
      console.error("Error loading reservation:", error);
      toast.error(t("OnlineCheckIn.errorreservation"));
      setData(prev => ({ ...prev, isLoading: false }));
    } finally {
      setData(prev => ({
      ...prev,
      reservationLoaded: true,
      isLoading: false
    }));

    }
  };

  // Efeito para carregar hotéis - só executa uma vez no início
  useEffect(() => {
    if(!cookies.currentHotel?.hotelId?.substring(0,8)==platformUid){
      loadHotels();
    }
  }, [platformUid]); // eslint-disable-line

  // Efeito para carregar perfil - depende dos cookies necessários
  useEffect(() => {
    if (cookies.currentHotel?.subscriptionKey && sUid && !data.profileLoaded) {
      loadProfile();
    }
  }, [cookies.currentHotel?.subscriptionKey, sUid]); // eslint-disable-line

  // Efeito para carregar reserva - só executa quando o perfil estiver carregado
  useEffect(() => {
    if (data.profileLoaded && !data.reservationLoaded) {
      loadReservation();
    }
  }, [data.profileLoaded]); // eslint-disable-line

  useEffect(() => {
    updateI18nLanguage(guestLanguage); // Use a nova função em vez de i18n.changeLanguage
    console.log(guestLanguage)
  }, [])


  return (
    <AnimatePresence mode="wait">
      {cS && cookies.currentHotel && cookies.cultureCode && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 10 }}
          transition={{
            type: "tween",
            ease: "easeOut",
            duration: 0.3
          }}
          className={`antialiased ${parseInt(cS) ? "OC-bg" : ``}`}
        >
          <section className={`flex-grow`}>
            <HostFormComplete
              cS={cS}
              showMenus={showMenus}
              hotel={cookies.currentHotel}
              reservation={reservation}
              setReservation={setReservation}
              cultureCode={cookies.cultureCode}
            />
          </section>
        </motion.div>
      )}
    </AnimatePresence>
  );
};