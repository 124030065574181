export const getPriceByLevel = (allPrices, priceLevel) => {
    if (!allPrices) return null;

    // Dividir a string de preços em um array de pares "level:price"
    const pricesList = allPrices.split(';');

    // Encontrar o preço para o nível desejado
    const priceItem = pricesList.find(item => {
      const [level] = item.split(':');
      return parseInt(level) === priceLevel;
    });

    if (!priceItem) return null;

    // Extrair o valor do preço e converter para número
    const [, price] = priceItem.split(':');
    return parseFloat(price);
  };