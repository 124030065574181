import { useTranslation } from "react-i18next";
import { useState } from 'react';
import { AlertTriangle } from "lucide-react";

/**
 * @module Email
 * @description Component for entering and validating a guest's email address.
 * This component includes an input field for email entry, validation logic,
 * and displays error messages for invalid email formats.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {function} props.handleGuestChange - Function to handle changes in guest data.
 * @param {Object} props.guest - The guest object containing email data.
 * @param {Array<Object>} props.requiredFields - List of required fields for validation,
 *                                               where each object contains:
 *                                               - {string} Field - The field name.
 *                                               - {boolean} IsMandatory - Indicates if the field is required.
 *
 * @returns {JSX.Element} The rendered email input field with validation.
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 *
 * @example
 * // Example usage of the Email component
 * <Email
 *   handleGuestChange={handleGuestChange}
 *   guest={guestData}
 *   requiredFields={requiredFields}
 * />
 */
export const Email = ({handleGuestChange, guest, requiredFields, error,emailError, setEmailError, cS}) => {
    const [t] = useTranslation("global");

    // Check if the Email field is required based on the provided validation rules
    const isRequired = requiredFields.some(
      field => field.Field === "Email" && field.IsMandatory);

      /**
     * @description Validates the provided email address using a regular expression.
     * This function checks if the email matches a valid format. It returns true
     * if the email is valid and false otherwise.
     *
     * @function validateEmail
     * @param {string} email - The email address to validate.
     * @returns {boolean} True if the email is valid, otherwise false.
     *
     * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
     * @version 1.0.0
     */
    const validateEmail = (email) => {
    // Regex pattern para validação de e-mail
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

    /**
     * @description Validates the email address on blur event.
     * This function is called when the email input field loses focus.
     * It checks if the email is valid and sets an appropriate error message.
     *
     * @function handleEmailValidation
     * @param {string} email - The email address entered by the user.
     * @returns {void}
     *
     * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
     * @version 1.0.0
     */
    const handleEmailValidation = (email) => {
    if (email && !validateEmail(email)) {
      setEmailError(`${t(`Email.error`)}`);
    } else {
      setEmailError(""); // Limpa o erro se o e-mail for válido
    }
  };

  return (
    <div>
    <label htmlFor="email" className={`pl-2 pr-2 rounded-xl inline-block translate-y-3.5 text-center justify-center translate-x-3 bg-white dark:bg-gxp_dark_3 text-sm font-medium text-gray-700 dark:text-white mb-1 ${parseInt(cS) ? "OC-bg" : ``}`} >{`${t(`Email.email`)}`}<span className="text-gray-400 dark:text-white ml-2">{isRequired?`( * )\u00A0`: ``}</span></label>
    <input name="Email" required={isRequired}  onChange={(e)=>handleGuestChange(guest.id,e.target.name,e.target.value)} onBlur={(()=>handleEmailValidation(guest.data.Email))} value={guest.data.Email || ""} type="email" id="email" className={`w-full px-4 py-2 text-xl rounded-lg border dark:text-white dark:bg-gxp_dark_3 ${error ? 'border-red-500' : 'border-gray-700 dark:border-white'} ${parseInt(cS) ? "OC-fields-borders OC-bg" : ``} focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out`} />
    {emailError && <div className="flex mt-1 items-center"><AlertTriangle className="text-red-500 mr-1" size={16} /><span className="text-red-500 text-sm">{emailError}</span></div> }{error && <div className="flex mt-1 items-center"> <AlertTriangle className="text-red-500 mr-1" size={16} /> <span className="text-red-500 text-sm">{`${t(`HostForm.fieldRequired`)}`}</span> </div>}
  </div>  )}