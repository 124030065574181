import { Link } from "react-router-dom"
import dayjs from 'dayjs'

export const SliderCard = ({element}) => {
  return (

    <div className="max-w-sm ">
        <div>
            <img className="rounded-t-lg h-[200px] w-[300px] overflow-hidden rounded-lg" src={element.src} alt={element.alt} />
        </div>
    </div>

  )
}
